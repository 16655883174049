import '../styles/pages/home.scss'
import TopHero from '../components/TopHero';
import FeaturedTitles from '../components/FeaturedTitles';
import { Link } from 'react-router-dom';
import TopNews from '../components/TopNews';
import { Helmet } from 'react-helmet';

const Home = ({games, news}) => {
    return (
        <div className="home-container">
            <Helmet>
                <title>NIS America, Inc.</title>
                <meta name="description" content="Check out the latest and greatest from NIS America all in one place! Find your new favorite game, along with the most recent updates." />
                <meta property="og:description" content="Check out the latest and greatest from NIS America all in one place! Find your new favorite game, along with the most recent updates." />
                <meta property="og:site_name" content="NIS America, Inc."/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://nisamerica.com/"/>
                <meta property="og:title" content="NIS America, Inc."/>
                <meta property="og:image" content="%PUBLIC_URL%/img/ui/NIS_logo.png"/>
                <meta property="og:image:url" content="%PUBLIC_URL%/img/ui/NIS_logo.png"/>
            </Helmet>
            <TopHero games={games}/>
            { news && <TopNews news={news}/>}
            <FeaturedTitles games={games}/>
            <Link to='/games'>
                <button className='home-button'>
                    VIEW GAMES
                </button>
            </Link>
        </div>
    );
};

export default Home;