import { Helmet } from 'react-helmet';
import '../../styles/pages/etc.scss'

const Contests = () => (
    <div className="etc-container">
        <Helmet>
            <title>Contest | NIS America, Inc.</title>
        </Helmet>
        <div className="etc-wrapper">
            <h1 className="title">
                Contests
            </h1>
            <h1 className="header">
                Official Rules
            </h1>
            <h1 className="header">
                No Purchase Required to Enter or Win
            </h1>
            <p>
                 1. Eligibility: NIS America, Inc. Contests (the “Contests”) are open only to individuals who are legal residents of the fifty (50) United States (including the District of Columbia) and Canada (excluding Quebec) and are 18 years of age or older. Employees of NIS America, Inc., their advertising or promotion agencies, those involved in the production, development, implementation or handling of Contests, any agents acting for, or on behalf of the above entities, their respective parent companies, officers, directors, subsidiaries, affiliates, licensees, service providers, former employees (until 30 days after last date of compensation), prize suppliers any other person or entity associated with the Contests (collectively “Contest Entities”) and/or the immediate family (spouse, parents, siblings and children) and household members (whether related or not) of each such employee, are not eligible. All U.S., federal, state and local and Canadian federal, provincial, and municipal laws and regulations apply. Void in Quebec, Puerto Rico and where prohibited by law.
                </p>
                <p>
                    2. Sponsor: The Contests are sponsored by NIS America, Inc., 4 Hutton Centre Dr., Suite 650, Santa Ana, CA 92707 (“Sponsor”).
                </p>
                <p>
                3. Agreement to Official Rules: Participation in the Contest constitutes entrant’s full and unconditional agreement to and acceptance of these Official Rules and the decisions of the Sponsor, which are final and binding. Winning a prize is contingent upon fulfilling all requirements set forth herein.
                </p>
                <p>
                4. Entry Period: The start and end dates/times of each Contest (the “Entry Period”) will be posted on the applicable Contest site.
                </p>
                <p>
                5. Entry: To enter a Contest, follow the instructions on the Contest site. Submission will result in one (1) entry. The number of times you can enter the Contest will be posted on the applicable Contest site. The use of any agencies or automated software to submit entries will void all entries submitted by that person.
                </p>
                <p>
                6. Winner Selection: At the conclusion of the Entry Period, Sponsor will select the potential winners by a measure of merit determined by the Sponsor of all eligible entries received during each Entry Period. The number of winners to be selected in a specific Contest will be posted on the applicable Contest site. The odds of being selected as a potential winner depend on the number of eligible entries received during the Entry Period as well as the entry's quality. Potential winners will be contacted via email and will be asked to provide their full name, age and mailing address within a specified time period. If a potential winner does not respond within the timeframe stated in the notification email, the Sponsor may select an alternate potential winner in his/her place from all entries received during the Entry Period. Limit one (1) prize per household per Contest.
                </p>
                <p>
                7. Requirements of the Potential Winners: Except where prohibited, the potential winners may be required to complete and return an affidavit of eligibility and liability/publicity release (the “Affidavit/Release”) within seven (7) days of being notified. If a potential winner fails to sign and return the Affidavit/Release within the required time period, an alternate entrant will be selected in his/her place of all entries received. In the event the potential winner of any prize is a Canadian resident, he/she will be required to correctly answer a time-limited, four mathematical question to be administered by email/mail to receive the prize.
                </p>
                <p>
                8. Prize(s): The prize(s) (including each prize’s approximate retail value) available to be won in a specific Contest will be posted on the Contest site. No cash or other substitution may be made, except by the Sponsor, who reserves the right to substitute a prize with another prize of equal or greater value if the prize is not available for any reason as determined by the Sponsor in its sole discretion. The winners are responsible for any taxes and fees associated with receipt or use of a prize.
                </p>
                <p>
                9. General Conditions: In the event that the operation, security, or administration of the Contest is impaired in any way for any reason, including, but not limited to fraud, virus, or other technical problem, the Sponsor may, in its sole discretion, either: (a) suspend the Contest to address the impairment and then resume the Contest in a manner that best conforms to the spirit of these Official Rules; or (b) award the prizes at random from among the eligible entries received up to the time of the impairment. The Sponsor reserves the right in its sole discretion to disqualify any individual it finds to be tampering with the entry process or the operation of the Contest or to be acting in violation of these Official Rules or in an unsportsmanlike or disruptive manner. Any attempt by any person to undermine the legitimate operation of the Contest may be a violation of criminal and civil law, and, should such an attempt be made, the Sponsor reserves the right to seek damages from any such person to the fullest extent permitted by law. The Sponsor’s failure to enforce any term of these Official Rules shall not constitute a waiver of that provision. In case of a dispute as to the owner of an entry, entry will be deemed to have been submitted by the authorized account holder of the screen name from which the entry is made. The authorized account holder is defined as the natural person who is assigned to an e-mail address by an Internet access provider, online service provider, or other organization responsible for assigning e-mail addresses for the domain associated with the submitted e-mail address.
                </p>
                <p>
                10. Release and Limitations of Liability: By participating in the Contest, entrants agree to release and hold harmless the Contest Entities from and against any claim or cause of action arising out of participation in the Contest or receipt or use of any prize, including, but not limited to: (a) unauthorized human intervention in the Contest; (b) technical errors related to computers, servers, providers, or telephone or network lines; (c) printing errors; (d) lost, late, postage-due, misdirected, or undeliverable mail; (e) errors in the administration of the Contest or the processing of entries; or (f) injury or damage to persons or property which may be caused, directly or indirectly, in whole or in part, from entrant’s participation in the Contest or receipt of any prize. Entrant further agrees that in any cause of action, the Contest Entities’ liability will be limited to the cost of entering and participating in the Contest, and in no event shall the Contest Entities be liable for attorney’s fees. Entrant waives the right to claim any damages whatsoever, including, but not limited to, punitive, consequential, direct, or indirect damages.
                </p>
                <p>
                11. Rights and ownership: By participating in the Contest, entrants hereby grant to the Sponsors a non-exclusive, perpetual, irrevocable, worldwide, transferable, sublicensable, and royalty free license to use, modify, reproduce, prepare derivative works of, distribute, perform, and display the Entry in any and all media throughout the world and for whatever purpose the Sponsors deem.
                </p>
                <p>
                12. Disputes: Except where prohibited, entrant agrees that any and all disputes, claims and causes of action arising out of, or connected with, the Contest or any prize awarded shall be resolved individually, without resort to any form of class action, and exclusively by the appropriate court located in the Orange County, State of California. All issues and questions concerning the construction, validity, interpretation and enforceability of these Official Rules, entrant’s rights and obligations, or the rights and obligations of the Sponsors in connection with the Contest, shall be governed by, and construed in accordance with, the laws of the State of California, without giving effect to any choice of law or conflict of law rules (whether of the State of California or any other jurisdiction), which would cause the application of the laws of any jurisdiction other than the State of California.
                </p>
                <p>
                13. Contest Results: To request the name of the winners, send a self-addressed stamped envelope (stating the specific Contest you are requesting the winners for) to NIS America, Inc., 4 Hutton Centre Dr., Suite 650, Santa Ana, CA 92707. Requests must be received within thirty (30) days from the end date of the applicable Contest. Canadian residents may omit return postage.
                </p>
                <p>
                14. Restrictions On Sales of Prizes: In the event that a digital code or similar form of online voucher is offered as a prize for our contests, the recipient of said prize is prohibited from selling it for any form of compensation. Should the recipient be found to be selling the code/voucher, they will be disqualified from participation in future contests.
                </p>
        </div>
    </div>
)

export default Contests;