import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import "../styles/components/gamecard.scss";

const GameCard = ({ game }) => {
  const [earliestRelease, setEarliestRelease] = useState("~~-~~-~~~~");

  useEffect(() => {
    if (game.releases !== undefined) {
      setEarliestRelease(game.releases.NA[Object.keys(game.releases.NA)[0]]);
      /*
            for(let key in game.releases){
                let arr = Object.values(game.releases[`${key}`])
                arr.forEach(date=>{
                    if(date < earliestRelease)
                        setEarliestRelease(date) 
                })
            }
            */
    }
  }, [earliestRelease, game.releases]);

  return (
    <motion.div
      initial={{ opacity: 0, y: "-20px" }}
      animate={{ opacity: 1, y: "0px" }}
    >
      <Link
        to={`${process.env.PUBLIC_URL}/games/${game.pathname}`}
        className="game-card-container"
      >
        <div
          className="game-card_image"
          style={{
            backgroundImage: `url(https://www.nisamerica.com/public/assets/${game.pathname}/tile.webp)`,
          }}
        ></div>
        <div className="game-card_info">
          <h2 className="game-card_title">
            {game.fullname.length < 65
              ? game.fullname
              : `${game.fullname.substring(0, 65)}...`}
          </h2>
        </div>
        <div className="game-card_bottom">
          <button className="more-info_btn">Learn More</button>
          <h1 className="game-card_release">
            Release: <br />
            {game.releases ? earliestRelease : "00/00/0000"}
          </h1>
        </div>
        <div className="platform-list">
          <h3 className="platform-title" style={{ paddingRight: "5px" }}>
            Platform(s):
          </h3>
          <p className="platform-shorthands">
            {
              //chanegs platform to short-hand
              game.platform.map((item, index) => {
                switch (item) {
                  case "iOS":
                    return `iOS${game.platform[index + 1] ? ", " : ""}`;
                  case "Nintendo Switch™":
                    return `Nintendo Switch™${
                      game.platform[index + 1] ? ", " : ""
                    }`;
                  case "Xbox One":
                    return `Xbox One${game.platform[index + 1] ? ", " : ""}`;
                  case "Nintendo 3DS":
                    return `3DS${game.platform[index + 1] ? ", " : ""}`;
                  case "Nintendo DS":
                    return `NDS${game.platform[index + 1] ? ", " : ""}`;
                  case "PlayStation®5":
                    return `PS5®${game.platform[index + 1] ? ", " : ""}`;
                  case "PlayStation®4":
                    return `PS4®${game.platform[index + 1] ? ", " : ""}`;
                  case "PlayStation®3":
                    return `PS3®${game.platform[index + 1] ? ", " : ""}`;
                  case "PlayStation®2":
                    return `PS2®${game.platform[index + 1] ? ", " : ""}`;
                  case "PlayStation®Vita":
                    return `PSV®${game.platform[index + 1] ? ", " : ""}`;
                  case "PlayStation Portable":
                    return `PSP®${game.platform[index + 1] ? ", " : ""}`;
                  case "PC":
                    return `PC${game.platform[index + 1] ? ", " : ""}`;
                  case "Wii":
                    return `Wii${game.platform[index + 1] ? ", " : ""}`;
                  case "Wii U":
                    return `Wii U${game.platform[index + 1] ? ", " : ""}`;
                  case "WiiWare":
                    return `WiiWare${game.platform[index + 1] ? ", " : ""}`;
                  case "Stadia":
                    return `Stadia${game.platform[index + 1] ? ", " : ""}`;

                  default:
                    return "";
                }
              })
            }
          </p>
        </div>
      </Link>
    </motion.div>
  );
};

export default GameCard;
