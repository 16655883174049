import { Link, useNavigate } from "react-router-dom";
import ArticleRelated from "../components/ArticleRelated";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import "../styles/pages/articlepage.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../components/Loading";
import RelatedTitles from "../components/RelatedTitles";

const ArticlePage = ({ article, articles, games }) => {

  const [loading, setLoading] = useState(false)

  //GET BODY
  const [game, setGame] = useState("")
  const [blog, setBlog] = useState(false)
  const [body, setBody] = useState("");
  const [blogList, setBlogList] = useState([]);
  const [blogIndex, setBlogIndex] = useState(-1)
  const [blogImage, setBlogImage] = useState("")
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true)
    window.scrollTo(0, 0);

    const fetchData = async () => {
      try {
        const response = await axios.get(`https://nisamerica.com/api/test/blog/${article.url}`);

        try {
          const imageCheck = await axios.get(`https://www.nisamerica.com/public/blogs/${article.url}/image.webp`);
          imageCheck && setBlogImage(`https://www.nisamerica.com/public/blogs/${article.url}/image.webp`)
        } catch (error) {
          setBlogImage(
            response.data.blog.thumbnail.length > 5 ?
            `${extractSrcFromHTML(response.data.blog.thumbnail)}` :
            `${process.env.PUBLIC_URL}/img/articles/${response.data.blog.type}.webp`
          )
        }

        setBlog(response.data.blog);
        setBody(response.data.blog.body)
        setLoading(false)
      } catch (error) {
        setError(error.message);
      }
    }

    {
      /*
        setBody( blog && blog.body);

        let filtered = articles.filter(blog  => blog.external === undefined && blog.body);
        setBlogList(filtered)

        const index = filtered.findIndex(blog => blog.url === article.url)
        setBlogIndex(index)
      */
    }
   
    fetchData();
      
  }, [article]);

  const extractSrcFromHTML = (htmlString) => {
    const srcRegex = /<img\s+[^>]*src="([^"]*)"[^>]*>/i;
    const match = htmlString.match(srcRegex);
  
    if (match && match[1]) {
      return match[1];
    }
  
    return null;
  };

  return (
    loading && !blog ? <Loading/> : <motion.div
    className="article-container"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    <Helmet>
        <title>{`${blog.title} | NIS America, Inc.`}</title>
        <meta name="description" content={blog.summary} />
        <meta property="og:description" content={blog.summary} />
        <meta property="og:title" content={`${blog.title} | NIS America, Inc.`}/>
        <meta property="og:image" content={blogImage}/>
    </Helmet>
    <div className="article_directory">
      <div className="wrapper">
        <Link to="/">HOME</Link> | <Link to="/blog">NEWS</Link> |{" "}<Link to={`/blog/${blog.url}`}>{blog.title}</Link>
      </div>
    </div>
    <section className="article_top" id="top">
      <div className="wrapper">
        <h1 className={`article_flag ${blog.type}`}>{blog.type}</h1>
        <div className="title-wrapper">
        <h1 className="article_title">{blog.title}</h1>
        <div className="article-nav-top">
            {
              blogList.length > 0 && blogIndex !== 0 &&
              <button className="article-nav_button home-button" onClick={()=>{
                navigate(`/blog/${blogList[blogIndex-1].url}`)
              }}>
                Previous Blog
            </button>
            }
            {
              blogList.length > 0 && blogIndex !== blogList.length-1 &&
              <button className="article-nav_button home-button" onClick={()=>{
                navigate(`/blog/${blogList[blogIndex+1].url}`)
              }}>
                  Next Blog
              </button>
            }
          </div>
        </div>
        <h2 className="article_publish-date">{new Date(blog.datePublished).toLocaleDateString('en-US')}</h2>

      </div>
    </section>
    <div className="article_img-container">
      <div className="wrapper">
       {
         <img
          src={blogImage}
          onError={({ currentTarget }) => {
            setBlogImage(`${process.env.PUBLIC_URL}/img/articles/${article.type}.webp`)
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = `${process.env.PUBLIC_URL}/img/articles/${article.type}.webp`;
           }} alt={article.title}
         />
       }
      </div>
    </div>
    <div className="article_body">
      <div className="wrapper">
        <h2 className="article_second-title">{blog.summary}</h2>
          {ReactHtmlParser(body)}
      </div>
    </div>
    <div className="article-nav">
      {
        blogList.length > 0 && blogIndex !== 0 &&
        <button className="article-nav_button home-button" onClick={()=>{
          navigate(`/blog/${blogList[blogIndex-1].url}`)
        }}>
          Previous Blog
      </button>
      }
      <a href="#top">
        <button className="home-button">TO TOP</button>
      </a>
      {
        blogList.length > 0 && blogIndex !== blogList.length-1 &&
        <button className="article-nav_button home-button" onClick={()=>{
          navigate(`/blog/${blogList[blogIndex+1].url}`)
        }}>
            Next Blog
        </button>
      }
    </div>
    <div className="article_featured-game">
      <div className="related-wrapper">
        <ArticleRelated game={game} games={games} articles={blog.related}/>
      </div>
    </div>  
  </motion.div>
  );
};

export default ArticlePage;
