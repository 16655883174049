import '../../styles/app.scss'

const LeftArrow = ({className, style, onClick, resetSlide}) => (
    <div className="arrow-container_left">
        <img src="/img/ui/arrow-left.png" alt="Left Arrow" className={`left-arrow ${className}`} style={{...style}} onClick={()=>{
            onClick()
            resetSlide()
        }
        }/>
    </div>
)
export default LeftArrow;