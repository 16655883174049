import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import '../styles/pages/home.scss'
const ErrorPage = () => {
    return (
        <div className="error-container">
            <Helmet>
                <title>404 Error | NIS America, Inc.</title>
            </Helmet>
            <div className="error-main">
                <div className="text">
                    <h1>4</h1>
                    <h1>0</h1>
                    <h1>4</h1>
                </div>
                <img src={`${process.env.PUBLIC_URL}/img/ui/panic.png`} alt="PANIC PRINNY" />
            </div>
            <p>
                This page doesn't exist, dood! <br/>Go back home or check out our games!
            </p>
            <Link to='/games'>
                <button style={{marginBottom: '25px'}} className="home-button">
                    VIEW GAMES
                </button>
            </Link>
            <Link to='/'>
                <button className="home-button">
                    GO HOME
                </button>
            </Link>
        </div>
    );
};

export default ErrorPage;