import ArticleCard from '../components/ArticleCard';
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import '../styles/pages/news.scss'
import { Helmet } from 'react-helmet';

const News = ({news, totalArticles}) => {
    const [search, changeSearch] = useState("")
    const [type, changeType] = useState("")
    const [results, setResults] = useState(news)

    const resetFilter = () => {
        changeType("")
        changeSearch("")
        setResults(news)
    }

    return (
        <motion.div className="news-container"
            initial={{opacity: 0}} 
            animate={{opacity: 1}} 
            exit={{opacity: 0}}
        >
            <Helmet>
                <title>News | NIS America, Inc.</title>
                <meta name="description" content="Keep up to date on the lastest updates, patches, and sales for all your favorite NIS America games on the NISA Blog!" />
                <meta property="og:description" content="Keep up to date on the lastest updates, patches, and sales for all your favorite NIS America games on the NISA Blog!" />
                <meta property="og:title" content={`News | NIS America, Inc.`}/>
                <meta property="og:image" content="%PUBLIC_URL%/img/ui/NIS_logo.png"/>
                <meta property="og:image:url" content="%PUBLIC_URL%/img/ui/NIS_logo.png"/>
            </Helmet>
            <div className="news-wrapper">
                <motion.h1 className="news-title"
                    initial={{opacity: 0, y: '-20px'}}
                    animate={{opacity: 1, y: '0px'}}
                    transition={{duration: .75}}
                >
                    RECENT NEWS
                </motion.h1>
                <div className="news-filter">
                    <input type="text" value={search} className='search-bar_news' placeholder="Get up to date, dood!" 
                    onKeyUp={(selection)=>{
                        if(selection.key === "Enter") {
                            changeSearch(selection.target.value)
                        }
                    }} onChange={(selection)=>{changeSearch(selection.target.value)}}/>
                    <select name="type" id="type" className="dropdown-news" value={type} onChange={(selection)=>{
                        changeType(selection.target.value)
                    }}>
                        <option value="all" hidden selected>Type</option>
                        <option value="all">All</option>
                        <option value="blog">Blog</option>
                        <option value="contest">Contest</option>
                        <option value="DLC">DLC</option>
                        <option value="event">Event</option>
                        <option value="sale">Sale</option>
                        <option value="stream">Stream</option>
                        <option value="update">Update</option>

                    </select>
                    <button className="news-filter_reset" onClick={()=>{resetFilter()}}>
                        RESET
                    </button>
                </div>
                <section className="article-card-list">
                {
                    news && news.map(((article, index) => {
                        return (
                            <ArticleCard article={article.url} index={index}/>
                        )
                    }))
                }
                {
                    (results.length === 0) && <h1 className='empty-list'>No titles found</h1>
                }
                </section>
            </div>
        </motion.div>
    );
};

export default News;