/*
    the login api is a get request to https://nisamerica.com/api/login/${username}/${password}
    the response token is response.data.token
    11:52
    to get all games, its a get request to https://nisamerica.com/api/games-private/{the-token}
    11:53
    to get one game, it is https://nisamerica.com/api/game-private/{game-path}/{the-token}
*/
import axios from "axios"
import { useState } from "react"
import { Helmet } from "react-helmet"
import { useNavigate } from "react-router-dom"
import '../styles/pages/login.scss'

const Login = ({setToken}) => {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [errorFlag, setErrorFlag] = useState(false)
    const navigate = useNavigate();

    const submitLogin = (e) => {
        e.preventDefault()
        axios.get(`https://nisamerica.com/api/nislogin/${username}/${password}`)
        .then(
            response => {
                if(response.data.success) {
                    const token = response.data.token
                    setErrorFlag(false)
                    localStorage.setItem("login_token", token)
                    setToken(true)
                    navigate("/")
                }
                else
                {
                    
                    setErrorFlag(true)
                }
            }
        )
        .catch(
            error => {
                console.log(error)
            }
        )
    }

    return (
        <div className="login-container">
            <Helmet>
                <title>Login | NIS America, Inc.</title>
            </Helmet>
            <div className="login-widget">
                <img src="./img/ui/NIS_logo.webp" alt="NIS America Logo" />
                <form className="login-form" onSubmit={e => {submitLogin(e)}}>
                    {
                        errorFlag && <h2 className="error-message">
                            There was an error loging in. Please try again!
                        </h2>
                    }
                    <h1>Login</h1>
                    <input type="text" placeholder="Username" className="input-text" 
                        onInput={e => {
                            setUsername(e.target.value)
                        }
                    }/>
                    <input type="password" placeholder="Password" name="" id="" className="input-text"
                        onInput={e => {
                            setPassword(e.target.value)}
                        }
                    />
                    <input type="submit" value="Login" className="input-button"/>
                </form>
            </div>
        </div>
    )
}

export default Login;