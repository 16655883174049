import axios from "axios";
import { useEffect, useState } from "react";
import RelatedTitles from "./RelatedTitles";
import { Link } from "react-router-dom";
import ESRB from "./ESRB";

const ArticleRelated = ({ game, games, articles}) => {

  return (
   games && game && <div className="article_related-title">
    <div className="related-title_img">
      <img
        src={`https://www.nisamerica.com/public/assets/${game.pathname}/tile.jpg`}
        alt="game-tile"
      />
      <div className="related-title_links">
        <Link to={`/games/${game.pathname}`}>
          <button className="related-title_page-link">LEARN MORE</button>
        </Link>
      </div>
    </div>
    <div className="related-title_info">
      <h1 className="related-title_title">{game.fullname}</h1>
      <div className="related-title_table">
        <div className="table-pair">
          <h1>Platform:</h1>
          <h2>
            {
              //List all genres and add punctuation
              game.genre.map((genre, i, { length }) =>
                length - 1 === i ? genre : `${genre}, `
              )
            }
          </h2>
        </div>
        {game.developer && (
          <div className="table-pair">
            <h1>Developer:</h1>
            <h2>{game.developer}</h2>
          </div>
        )}
        <div className="table-pair">
          <h1>Players:</h1>
          <h2>{game.player}</h2>
        </div>
        <div className="table-pair">
          <h1>Genre(s):</h1>
          <h2>
            {
              //List all genres and add punctuation
              game.genre.map((genre, i, { length }) =>
                length - 1 === i ? genre : `${genre}, `
              )
            }
          </h2>
        </div>
        <div className="table-pair">
          <h1>Text Language(s):</h1>
          <h2>
            {
              //List all genres and add punctuation
              game.textLanguages.map((language, i, { length }) =>
                length - 1 === i ? language : `${language}, `
              )
            }
          </h2>
        </div>
      </div>
      <div className="table-pair">
        <h1>Rating:</h1>
        <ESRB
          rating={game.esrb}
          lines={game.esrbLines}
          bottom={game.esrbBottom}
        />
      </div>
    </div>
    {
      game && games && <RelatedTitles game={game} games={games} article={true} articles={articles}/>
    }
  </div>
  )
};

export default ArticleRelated;
