import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import '../../styles/pages/etc.scss'
import Apply from "./Apply";

const CareerPage = ({career}) => (
    <div className="etc-container">
        <Helmet>
            <title>{career.title} | Careers | NIS America, Inc.</title>
        </Helmet>
        <div className="career-wrapper">
            <>
                {
                    ReactHtmlParser(career.body)
                }
            </>
            <Apply title={career.title} sendTo={career.emailTo}/>
        </div>
    </div>
)

export default CareerPage;