import { Link, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet'
import ReactHtmlParser from 'react-html-parser'
import axios from 'axios'
import ReactGA from 'react-ga4'
import ESRB from '../components/ESRB'
import Slider from 'react-slick'
import LeftArrow from '../components/ui/LeftArrow'
import RightArrow from '../components/ui/RightArrow'
import RelatedTitles from '../components/RelatedTitles'
import '../styles/pages/gamepage.scss'
import YouTube from 'react-youtube'

const GamePage = ({ game, games, changeRegion, changePlatform, token }) => {
    //SCROLL TO TOP ON LOAD
    const [visible, setVisible] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0)
        setVisible(true)
    }, [])

    //AGE GATE CHECK
    const [canView, setEligibilty] = useState(true)
    const navigate = useNavigate()

    const goToGate = () => {
        let path = '/age'
        navigate(path, { replace: true })
        window.scrollTo(0, 0)
    }
    const timestampCheck = () => {
        const previous = new Date(localStorage.getItem('ageTimestamp'))
        const today = new Date()

        if (localStorage.getItem('ageTimestamp') === null) return true
        else return (today.getTime() - previous.getTime()) / (1000 * 3600 * 24) > 28
    }

    useEffect(() => {
        if (
            (localStorage.getItem('age') === null && (game.esrb === 'M' || game.esrb === 'T-M')) ||
            (timestampCheck() && (game.esrb === 'M' || game.esrb === 'T-M'))
        )
            goToGate()
        else if (localStorage.getItem('age') < 17 && (game.esrb === 'M' || game.esrb === 'T-M')) {
            setEligibilty(false)
        }
    }, [])

    //GET SCREENSHOTS
    const [screenshots, setScreenshots] = useState([])
    useEffect(() => {
        if (token === true) {
            axios
                .get(`https://nisamerica.com/api/game-private/${game.pathname}/${localStorage.getItem('login_token')}`)
                .then((response) => {
                    const screenshotList = response.data.screenshots
                    const retailerLinks = response.data.game.retailerLinks
                    setScreenshots(screenshotList)
                    setEditions([...new Set(retailerLinks.map((item) => item.edition))])
                    setLinks(retailerLinks)
                })
                .catch((error) => {
                    axios
                        .get(`https://www.nisamerica.com/api/game/${game.pathname}`)
                        .then((response) => {
                            const screenshotList = response.data.screenshots
                            const retailerLinks = response.data.game.retailerLinks
                            setScreenshots(screenshotList)
                            setEditions([...new Set(retailerLinks.map((item) => item.edition))])
                            setLinks(retailerLinks)
                        })
                        .catch((error) => {
                            console.log('An error has occured')
                            console.log(error)
                        })
                })
        } else {
            axios
                .get(`https://www.nisamerica.com/api/game/${game.pathname}`)
                .then((response) => {
                    const screenshotList = response.data.screenshots
                    const retailerLinks = response.data.game.retailerLinks
                    setScreenshots(screenshotList)
                    setEditions([...new Set(retailerLinks.map((item) => item.edition))])
                    setLinks(retailerLinks)
                })
                .catch((error) => {
                    console.log('An error has occured')
                    console.log(error)
                })
        }
    }, [])

    //REGION, PLATFORM, MOST RECENT GAME SETUP
    useEffect(() => {
        if (!game.region.includes(localStorage.getItem('region'))) changeRegion(game.region[0])
        if (!game.platform.includes(localStorage.getItem('platform'))) changePlatform(game.platform[0])
        localStorage.setItem('recentGame', game.pathname)
    })

    //RETAILER LINKS SETUP
    const [retailerLinks, setLinks] = useState([])
    const [editions, setEditions] = useState([])

    //SETUP EARLIEST RELEASE DATE
    const [earliestRelease, setEarliestRelease] = useState('~~-~~-~~~~')

    useEffect(() => {
        if (game.releases !== undefined) {
            for (let key in game.releases) {
                let arr = Object.values(game.releases[`${key}`])
                arr.forEach((date) => {
                    if (date < earliestRelease) setEarliestRelease(date)
                })
            }
        }
    }, [earliestRelease, game.releases])

    //SLIDER SETTINGS AND REFS
    const [mainRef, setMain] = useState(null)
    const [navRef, setNav] = useState(null)
    const [currentSlide, setCurrent] = useState('')
    const [previousSlide, setPrevious] = useState('')

    useEffect(() => {
        setPrevious(currentSlide)
        if (currentSlide.target && previousSlide.target) {
            if (currentSlide.target.id !== previousSlide.target.id) {
                previousSlide.target && previousSlide.target.pauseVideo()
            }
        }
    }, [currentSlide])

    //REMOVES HTML FROM GAME DESCRIPTION TO BE USED IN META TAGS
    const [metaBio, setMetaBio] = useState('')

    useEffect(() => {
        const regex = new RegExp('<[^>]*>', 'g')
        const subst = ``
        const result = game.hpBody.replace(regex, subst)
        setMetaBio(result)
    }, [])

    //FUNCTION USED FOR VIDEO SLIDES

    const resetSlide = () => {
        currentSlide.target && currentSlide.target.pauseVideo()
    }

    const _onPlay = (event) => {
        setCurrent(event)
    }

    //SLIDER SETTINGS

    const settings = {
        infinite: true,
        arrows: false,
        beforeChange: (oldIndex, newIndex) => {
            setCurrent(newIndex)
        },
    }

    const nav = {
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        focusOnSelect: true,
        nextArrow: <RightArrow resetSlide={resetSlide} />,
        prevArrow: <LeftArrow resetSlide={resetSlide} />,
    }

    //MISC. VALUE(S) AND FUNCTIONS
    const longName = game.fullname.length > 45 ? true : false
    const longFirst = game.fullname.indexOf(' ') > 10
    const getShorthand = (platform) => {
        switch (platform) {
            case 'iOS':
                return `iOS`
            case 'Nintendo Switch™':
                return `Nintendo Switch™`
            case 'Xbox One':
                return `Xbox One`
            case 'Xbox':
                return `Xbox`
            case 'Nintendo 3DS':
                return `3DS`
            case 'Nintendo DS':
                return `NDS`
            case 'PlayStation®5':
                return `PS5®`
            case 'PlayStation®4':
                return `PS4®`
            case 'PlayStation®3':
                return `PS3®`
            case 'PlayStation®2':
                return `PS2®`
            case 'PlayStation®Vita':
                return `PSV®`
            case 'PlayStation Portable':
                return `PSP®`
            case 'PC':
                return `PC`
            case 'Wii':
                return `Wii`
            case 'Wii U':
                return `Wii U`
            case 'WiiWare':
                return `WiiWare`
            case 'Stadia':
                return `Stadia`
            default:
                return platform
        }
    }

    //GA4 EVENT TRACKING FUNCTION
    const eventTrack = (category, action, label) => {
        ReactGA.event({
            category: category,
            action: action,
            label: label,
        })
    }

    //IF USER ISN'T OLD ENOUGH RETURN AGE BLOCK
    if (!canView) {
        window.scrollTo(0, 0)
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={`game-container ${visible && 'visible'}`}
                style={{
                    backgroundImage: `url(https://www.nisamerica.com/public/assets/${game.pathname}/background.webp)`,
                }}
            >
                <div className='age-wrapper'>
                    <h1>This game contains content that is not suitable for you at this time.</h1>
                    <h1>Explore other games below!</h1>
                    <Link className='button' to='/games'>
                        More Games
                    </Link>
                </div>
            </motion.div>
        )
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='game-container'
            style={{
                backgroundImage: `url(https://www.nisamerica.com/public/assets/${game.pathname}/background.webp)`,
            }}
        >
            <Helmet>
                <title>{game.fullname} | NIS America, Inc. </title>
                <meta name='description' property='og:description' content={metaBio} />
                <meta property='og:description' content={metaBio} />
                <meta property='og:title' content={`${game.fullname} | NIS America, Inc.`} />
                <meta
                    property='og:image'
                    content={`https://www.nisamerica.com/public/assets/${game.pathname}/tile.webp`}
                />
            </Helmet>
            <div className='game-wrapper'>
                <div className='title'>
                    <div className='title-wrapper'>
                        <Link to='/'>HOME</Link>
                        <Link to='/games'> | GAMES</Link>
                        <Link
                            to={`/games/${game.pathname}`}
                            style={{ textTransform: 'uppercase' }}
                            className=''
                        >{` | ${game.fullname}`}</Link>
                    </div>
                </div>
                <header className='game-hero'>
                    <div className='game-hero_top'>
                        <aside className='game-hero_info'>
                            <h1
                                className={`game-hero_info_title ${longName && 'long-name'} ${
                                    longFirst ? 'long-first' : 'regular-name'
                                }`}
                            >
                                {game.fullname}
                            </h1>
                            <h2 className='game-hero_info_release'>
                                {game.hasReleased
                                    ? 'Currently Available'
                                    : earliestRelease.includes('Coming')
                                    ? `${earliestRelease}`
                                    : `Available ${earliestRelease}`}
                            </h2>{' '}
                            <a href='#preorder'>
                                <button
                                    className={`game-hero_info_button ${game.hasReleased ? 'buy' : 'preorder'}`}
                                    onClick={() => {
                                        eventTrack('Store Link', 'Game Page - Hero', game.fullname)
                                    }}
                                >
                                    {game.hasReleased ? 'BUY NOW' : 'PREORDER NOW'}
                                </button>
                            </a>
                            {!game.broken && (
                                <a
                                    href={
                                        game.officialURL
                                            ? game.officialURL
                                            : `https://www.nisamerica.com/${game.pathname}`
                                    }
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <button
                                        className='official-website'
                                        onClick={() => {
                                            eventTrack('Website Link', 'Game Page - Hero', game.fullname)
                                        }}
                                    >
                                        OFFICIAL WEBSITE
                                    </button>
                                </a>
                            )}
                        </aside>
                        <div className='game-hero_content'>
                            <div className='slider-wrapper'>
                                <Slider {...settings} asNavFor={navRef} ref={(slider) => setMain(slider)}>
                                    {game.youtube !== undefined &&
                                        game.youtube.Trailer.map(
                                            (link, index) =>
                                                link.includes('you') && (
                                                    <div className='video-container' key={`trailer-${index}`}>
                                                        <div className='video-wrapper'>
                                                            <div className='iframe'>
                                                                <YouTube
                                                                    videoId={link.substring(
                                                                        link.length - 11,
                                                                        link.length
                                                                    )}
                                                                    onPlay={_onPlay}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                        )}
                                    {screenshots &&
                                        screenshots.map((link, index) => (
                                            <div className='game-hero_slide' key={`ss-${index}`}>
                                                <img src={`${link}`} alt='screenshot' />
                                            </div>
                                        ))}
                                    {game.youtube &&
                                        game.youtube['Music Clip'] !== undefined &&
                                        game.youtube['Music Clip'].map(
                                            (link, index) =>
                                                link.includes('you') && (
                                                    <div className='video-container' key={`trailer-${index}`}>
                                                        <div className='video-wrapper'>
                                                            <div className='iframe'></div>
                                                        </div>
                                                    </div>
                                                )
                                        )}
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className='game-hero_bottom'>
                        <Slider {...nav} asNavFor={mainRef} ref={(slider) => setNav(slider)}>
                            {game.youtube !== undefined &&
                                game.youtube.Trailer.map(
                                    (link, index) =>
                                        link.includes('you') && (
                                            <div
                                                className='game-hero_nav'
                                                key={`thumbnail-${index}`}
                                                onClick={() => {
                                                    currentSlide.target && currentSlide.target.pauseVideo()
                                                }}
                                            >
                                                <img
                                                    src={`https://i3.ytimg.com/vi/${link.substring(
                                                        link.length - 11,
                                                        link.length
                                                    )}/maxresdefault.jpg`}
                                                    alt='screenshot'
                                                />
                                            </div>
                                        )
                                )}
                            {screenshots &&
                                screenshots.map((link, index) => (
                                    <div
                                        className='game-hero_nav'
                                        key={index}
                                        onClick={() => {
                                            currentSlide.target && currentSlide.target.pauseVideo()
                                        }}
                                    >
                                        {' '}
                                        <img src={link} alt='Feature Card' width='100%' height='100%' />
                                    </div>
                                ))}
                            {game.youtube &&
                                game.youtube['Music Clip'] !== undefined &&
                                game.youtube['Music Clip'].map(
                                    (link, index) =>
                                        link.includes('you') && (
                                            <div
                                                className='game-hero_nav'
                                                key={`thumbnail-${index}`}
                                                onClick={() => {
                                                    currentSlide.target && currentSlide.target.pauseVideo()
                                                }}
                                            >
                                                <img
                                                    src={`https://i3.ytimg.com/vi/${link.substring(
                                                        link.length - 11,
                                                        link.length
                                                    )}/maxresdefault.jpg`}
                                                    alt='screenshot'
                                                />
                                            </div>
                                        )
                                )}
                        </Slider>
                    </div>
                </header>
                <section className='game-body-container'>
                    <div className='game-info-container'>
                        <div className='game-description'>
                            <div className='game-description_top'>
                                <h1 className='game-description_title'>DESCRIPTION</h1>
                            </div>
                            <div className='game-description_info'>
                                {
                                    //Converts "string html" to actual html
                                    ReactHtmlParser(game.hpBody)
                                }
                            </div>
                        </div>
                        <div className='game-specs'>
                            <h1 className='game-specs_title'>SPECIFICATIONS</h1>
                            <table className='game-specs_wrapper'>
                                <tr className='spec-release'>
                                    <th className='spec-title'>Release Date:</th>
                                    <td className='spec-info'>
                                        {game.region.includes(localStorage.getItem('region')) &&
                                            game.platform.includes(localStorage.getItem('platform')) &&
                                            game.releases[`${localStorage.getItem('region')}`][
                                                `${localStorage.getItem('platform')}`
                                            ]}
                                    </td>
                                </tr>
                                <tr className='spec-pair'>
                                    <th className='spec-title'>
                                        {game.platform.length > 1 ? 'Platforms' : 'Platform'}:
                                    </th>
                                    <td className='spec-info'>
                                        {
                                            //List all platforms and add punctuation
                                            game.platform.map((platform, i, { length }) =>
                                                length - 1 === i ? platform : `${platform}, `
                                            )
                                        }
                                    </td>
                                </tr>
                                <tr className='spec-pair'>
                                    <th className='spec-title'>{game.genre.length > 1 ? 'Genres' : 'Genre'}:</th>
                                    <td className='spec-info'>
                                        {
                                            //List all genres and add punctuation
                                            game.genre.map((genre, i, { length }) =>
                                                length - 1 === i ? genre : `${genre}, `
                                            )
                                        }
                                    </td>
                                </tr>
                                <tr className='spec-pair'>
                                    <th className='spec-title'>Players:</th>
                                    <td className='spec-info'>{game.player}</td>
                                </tr>
                                {
                                    <tr className='spec-pair'>
                                        <th className='spec-title'>
                                            {game.textLanguages.length > 1 ? 'Text Languages' : 'Text Language'}:
                                        </th>
                                        <td className='spec-info'>
                                            {game.textLanguages.map((language, i, { length }) =>
                                                length - 1 === i ? language : `${language}, `
                                            )}
                                        </td>
                                    </tr>
                                }
                                {
                                    <tr className='spec-pair'>
                                        <th className='spec-title'>
                                            {game.voiceLanguages.length > 1 ? 'Audio Languages' : 'Audio Language'}:
                                        </th>
                                        <td className='spec-info'>
                                            {game.voiceLanguages.map((language, i, { length }) =>
                                                length - 1 === i ? language : `${language}, `
                                            )}
                                        </td>
                                    </tr>
                                }
                                {game.developer && (
                                    <tr className='spec-pair'>
                                        <th className='spec-title'>Developer/Licensor:</th>
                                        <td className='spec-info'>{game.developer}</td>
                                    </tr>
                                )}
                                <tr className='selection-wrapper'>
                                    <div className='region-selector'>
                                        <th className='spec-title'>Preferred Region:</th>
                                        <div className='region-button-list'>
                                            {game.region.length > 0 &&
                                                game.region.map((e) => (
                                                    <button
                                                        className={`region-button ${
                                                            localStorage.getItem('region') === e && 'selected'
                                                        }`}
                                                        onClick={() => {
                                                            changeRegion(e)
                                                        }}
                                                    >
                                                        {e}
                                                    </button>
                                                ))}
                                        </div>
                                    </div>
                                    <div className='region-selector'>
                                        <th className='spec-title'>Preferred Platform:</th>
                                        <div className='region-button-list'>
                                            {game.platform.length > 0 &&
                                                game.platform.map((e) => (
                                                    <button
                                                        className={`region-button ${
                                                            localStorage.getItem('platform') === e && 'selected'
                                                        }`}
                                                        onClick={() => {
                                                            changePlatform(e)
                                                        }}
                                                    >
                                                        {getShorthand(e)}
                                                    </button>
                                                ))}
                                        </div>
                                    </div>
                                </tr>
                                <tr className='spec-rating'>
                                    <th className='spec-title'>Rating:</th>
                                    <div className='rating-container'>
                                        {game.esrbSpecial && localStorage.getItem('region') === 'NA' ? (
                                            <div className='spec-info'>
                                                <span style={{ fontWeight: 600 }}>ESRB: </span>
                                                <ESRB
                                                    rating={
                                                        game.esrbSpecial &&
                                                        game.esrbSpecial[localStorage.getItem('platform')]
                                                    }
                                                    lines={
                                                        game.esrbSpecial &&
                                                        game.esrbSpecial[localStorage.getItem('platform')].includes(
                                                            'RP'
                                                        )
                                                            ? ''
                                                            : game.esrbLines
                                                    }
                                                    bottom={
                                                        game.esrbSpecial &&
                                                        game.esrbSpecial[localStorage.getItem('platform')].includes(
                                                            'RP'
                                                        )
                                                            ? ''
                                                            : game.esrbBottom
                                                    }
                                                />
                                            </div>
                                        ) : localStorage.getItem('region') === 'NA' ? (
                                            <div className='spec-info'>
                                                <span style={{ fontWeight: 600 }}>ESRB: </span>
                                                <ESRB
                                                    rating={game.esrb}
                                                    lines={game.esrbLines}
                                                    bottom={game.esrbBottom}
                                                />
                                            </div>
                                        ) : localStorage.getItem('region') === 'ANZ' ? (
                                            <td className='spec-info'>
                                                <span style={{ fontWeight: 600 }}>ACB: </span>
                                                {game.acb}
                                            </td>
                                        ) : (
                                            <>
                                                <td className='spec-info'>
                                                    <span style={{ fontWeight: 600 }}>PEGI: </span>
                                                    {game.pegi}
                                                </td>{' '}
                                                <br />
                                                <td className='spec-info'>
                                                    <span style={{ fontWeight: 600 }}>USK: </span>
                                                    {game.usk}
                                                </td>
                                            </>
                                        )}
                                    </div>
                                </tr>
                            </table>
                        </div>
                        <h1 className='copyright'>{game.developerCopyright}</h1>
                    </div>
                    <div className='game-products-container' id='preorder'>
                        <div className='product-link_nav'>
                            <h1 className='game-product_title'>
                                {game.hasReleased ? 'Buy your copy today!' : 'Preorder your copy today!'}
                            </h1>

                            <div className='product-region-selector'>
                                <h1>REGION</h1>
                                <div className='region-button-list'>
                                    {game.region.length > 0 &&
                                        game.region.map(
                                            (e) =>
                                                retailerLinks.some((info) => info['region'] === e) && (
                                                    <button
                                                        className={`region-button ${
                                                            localStorage.getItem('region') === e && 'selected'
                                                        }`}
                                                        onClick={() => {
                                                            changeRegion(e)
                                                        }}
                                                    >
                                                        {e}
                                                    </button>
                                                )
                                        )}
                                </div>
                            </div>

                            <div className='product-platform-selector'>
                                <h1>PLATFORM</h1>
                                <div className='region-button-list'>
                                    {game.platform.length > 0 &&
                                        game.platform.map(
                                            (e) =>
                                                retailerLinks.some((info) => info['platform'] === e) && (
                                                    <button
                                                        className={`region-button ${
                                                            localStorage.getItem('platform') === e && 'selected'
                                                        }`}
                                                        onClick={() => {
                                                            changePlatform(e)
                                                        }}
                                                    >
                                                        {getShorthand(e)}
                                                    </button>
                                                )
                                        )}
                                </div>
                            </div>
                        </div>
                        <div className='retail-links-list'>
                            <div className='link-list-container'>
                                {editions.map((edition) => {
                                    return (
                                        <>
                                            <div className='edition-wrapper'>
                                                {retailerLinks.some(
                                                    (info) =>
                                                        info['platform'] === localStorage.getItem('platform') &&
                                                        info['edition'] === edition &&
                                                        (info['region'] === localStorage.getItem('region') ||
                                                            localStorage.getItem('platform') === 'PC' ||
                                                            localStorage.getItem('platform') === 'Stadia')
                                                ) && edition === 'Play the Demo!' ? (
                                                    <h3 className='demo-title'>Play the Demo!</h3>
                                                ) : (
                                                    <h1>{edition}</h1>
                                                )}
                                                <div className='link-list-wrapper'>
                                                    {retailerLinks.map(
                                                        (info) =>
                                                            info.platform ===
                                                                (localStorage.getItem('platform')
                                                                    ? localStorage.getItem('platform')
                                                                    : game.platform[0]) &&
                                                            (info.region ===
                                                                (localStorage.getItem('region')
                                                                    ? localStorage.getItem('region')
                                                                    : game.region[0]) ||
                                                                info.region === 'ALL') &&
                                                            info.edition === edition && (
                                                                <a
                                                                    href={info.url}
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                >
                                                                    <button
                                                                        className='retail-link-button'
                                                                        onClick={() => {
                                                                            eventTrack(
                                                                                'Product Link',
                                                                                game.fullname,
                                                                                `${info.store} - ${info.edition} - ${info.platform} - ${info.region}`
                                                                            )
                                                                        }}
                                                                    >
                                                                        {info.store}
                                                                    </button>
                                                                </a>
                                                            )
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </section>
                {game && games && <RelatedTitles game={game} games={games} articles={[game.pathname]} />}
            </div>
        </motion.div>
    )
}

export default GamePage
