import ReactHtmlParser from 'react-html-parser'
import { Link } from 'react-router-dom'

const FeaturedCard = ({ game }) => {
    return (
        <Link to={`/games/${game.pathname}`} className='link-feature'>
            <div className='feature-card_container'>
                <div className='info-wrapper'>
                    <div className={`feature-card_info ${game.fullname.length > 50 && 'longname'}`}>
                        <h1 className='feature-card_title'>{game.fullname}</h1>
                        <div className='hidden-values'>
                            <p className='feature-card_bio'>{ReactHtmlParser(`${game.hpBody.substring(0, 250)}...`)}</p>
                            <div className='feature-card_bottom'>
                                <button className='feature-card_button'>LEARN MORE</button>
                                <div className='feature-card_platform-list'>
                                    <p className='platform-title' style={{ paddingRight: '5px' }}>
                                        Platform(s):
                                    </p>
                                    <p className='platform-shorthands'>
                                        {
                                            //changes platform to short-hand
                                            game.platform.map((item, index) => {
                                                switch (item) {
                                                    case 'iOS':
                                                        return `iOS${game.platform[index + 1] ? ', ' : ''}`
                                                    case 'Nintendo Switch™':
                                                        return `Nintendo Switch™${game.platform[index + 1] ? ', ' : ''}`
                                                    case 'Xbox One':
                                                        return `Xbox One${game.platform[index + 1] ? ', ' : ''}`
                                                    case 'Nintendo 3DS':
                                                        return `3DS${game.platform[index + 1] ? ', ' : ''}`
                                                    case 'Nintendo DS':
                                                        return `NDS${game.platform[index + 1] ? ', ' : ''}`
                                                    case 'PlayStation®5':
                                                        return `PS5®${game.platform[index + 1] ? ', ' : ''}`
                                                    case 'PlayStation®4':
                                                        return `PS4®${game.platform[index + 1] ? ', ' : ''}`
                                                    case 'PlayStation®3':
                                                        return `PS3®${game.platform[index + 1] ? ', ' : ''}`
                                                    case 'PlayStation®2':
                                                        return `PS2®${game.platform[index + 1] ? ', ' : ''}`
                                                    case 'PlayStation®Vita':
                                                        return `PSV®${game.platform[index + 1] ? ', ' : ''}`
                                                    case 'PlayStation Portable':
                                                        return `PSP®${game.platform[index + 1] ? ', ' : ''}`
                                                    case 'PC':
                                                        return `PC${game.platform[index + 1] ? ', ' : ''}`
                                                    case 'Wii':
                                                        return `Wii${game.platform[index + 1] ? ', ' : ''}`
                                                    case 'Wii U':
                                                        return `Wii U${game.platform[index + 1] ? ', ' : ''}`
                                                    case 'WiiWare':
                                                        return `WiiWare${game.platform[index + 1] ? ', ' : ''}`
                                                    case 'Stadia':
                                                        return `Stadia${game.platform[index + 1] ? ', ' : ''}`

                                                    default:
                                                        return ''
                                                }
                                            })
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='feature-bg'>
                    <picture>
                        {/* WebP format */}
                        <source
                            media='(max-width: 430px)'
                            srcSet={`https://www.nisamerica.com/public/assets/${game.pathname}/background.webp`}
                            type='image/webp'
                        />
                        <source
                            media='(max-width: 1200px)'
                            srcSet={`https://www.nisamerica.com/public/assets/${game.pathname}/background.webp`}
                            type='image/webp'
                        />
                        <source
                            srcSet={`https://www.nisamerica.com/public/assets/${game.pathname}/background.webp`}
                            type='image/webp'
                        />
                        {/* PNG format */}
                        <img
                            srcSet={`https://www.nisamerica.com/public/assets/${game.pathname}/background.webp 430w, https://www.nisamerica.com/public/assets/${game.pathname}/background.webp 1024w, https://www.nisamerica.com/public/assets/${game.pathname}/background.webp 1920w`}
                            alt='Feature Card'
                            width='100%'
                            height='100%'
                            style={{
                                objectFit: 'cover',
                                objectPosition: 'center',
                            }}
                        />
                    </picture>
                </div>
            </div>
        </Link>
    )
}

export default FeaturedCard
