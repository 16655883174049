import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie'
import GA from "./utility/GA";

export default function Cookie() {

  const [essentialCookies, setEssential] = useState(true);
  const [funcationalCookies, setFunctional] = useState(true);
  const [analyticsCookies, setAnalytics] = useState(true);
  const [cookiesClose, setCookiesClose] = useState(true)
  const [cookieSettings, openSettings] = useState(false)
  
  useEffect(()=>{
    if(Cookies.get('nisaFunctional') && Cookies.get('nisaAnalytics')) {
      setFunctional(Cookies.get('nisaFunctional'))
      setAnalytics(Cookies.get('nisaAnalytics'))
    } else {
      setCookiesClose(false)
    }
  },[])

  const updateCookie = (cookieName) => {
    if(cookieName === 'functional') {
      setFunctional(!funcationalCookies)
      Cookies.set('nisaFunctional', !funcationalCookies, { expires: 30 })
    }
      
    if(cookieName === 'analytics') {
      setAnalytics(!analyticsCookies)
      Cookies.set('nisaAnalytics', !analyticsCookies, { expires: 30 })
    }
  }


    return (
      <>
        {
          cookieSettings &&
            <article className="cookie-settings">
              <section className="cookie-settings_wrapper">
                <h1 className="cookie-header">
                  Your cookie settings:
                </h1>
                <div className="cookie-desc">
                  <p>
                    <span className="bold">Necessary Cookies:</span> These cookies are necessary for the proper functioning of our website. They enable core functionality such as security, network management, and accessibility. You cannot opt out of these cookies as they are essential for the website to function.  
                  </p>
                  <p>
                    <span className="bold">Functional Cookies:</span> Functional cookies enhance the usability and performance of our website. They allow us to remember your preferences, such as language selection and font size, to provide a more personalized experience.
                  </p>
                  <p>
                    <span className="bold">Analytics Cookies:</span> We utilize analytics cookies to gather information about how visitors interact with our website. This helps us improve the site&apos;s content and structure. The data collected is aggregated and anonymized.
                  </p>
                </div>
                
                <h1 className="cookie-sub">Cookie Settings:</h1>
                <div className="cookie-list">
                  <button className={`cookie-button always-cookie`}>
                    <h1 className="button-text">
                      Necessary Cookies:
                    </h1> 
                    <div className="indicator indicator-on">
                      {essentialCookies ? "ON" : "OFF"}
                    </div>
                  </button>
                  <button className={`cookie-button ${funcationalCookies && 'allowed-cookie'}`}
                    onClick={()=>updateCookie('functional')}
                  >
                    <h1 className="button-text">
                      Functional Cookies:
                    </h1>
                    <div className={funcationalCookies ? 'indicator indicator-on' : 'indicator'}>
                      {funcationalCookies ? "ON" : "OFF"}
                    </div>
                  </button>
                  <button className={`cookie-button ${analyticsCookies && 'allowed-cookie'}`}
                    onClick={()=>updateCookie('analytics')}
                  >
                    <h1 className="button-text">
                      Analytics Cookies:
                    </h1>
                    <div className={analyticsCookies ? 'indicator indicator-on' : 'indicator'}>
                      {analyticsCookies ? "ON" : "OFF"}
                    </div>
                  </button>
                  <button className="save-cookies cookie-button" onClick={()=>{
                    openSettings(false)
                    setCookiesClose(true)
                  }}>Save my preferences</button>
                </div>
                <button className="cookie-settings-x" onClick={()=>{
                  openSettings(false)
                }}>
                  X
                </button>
              </section>
            </article>
        }
        <div className={`cookie-container ${cookiesClose && "cookies-hidden"}`}>
          <h1>Your Cookie Settings:</h1>
          <p className="cookie-text">
            We use necessary cookies to make our website functional, enhance your browsing experience, and analyze our traffic and performance. <Link to={'/privacy-policy#cookie'}>Read More</Link>
          </p>
          <div className="cookie-buttons">
            <button className={`cookie-button ${analyticsCookies && 'allowed-cookie'}`}
              onClick={()=>{
                updateCookie('functional')
                updateCookie('analytics')
              }}
            >
              All Cookies: {analyticsCookies ? "ON" : "OFF"}
            </button>
            <button className={`cookie-button always-cookie`}>
              Necessary Cookies: {"ON"}
            </button>
            <button className={`cookie-button`}
              onClick={()=>openSettings(true)}
            >
              Cookies Settings
            </button>
          </div>
          <button className="cookie-x" onClick={()=>{
            setCookiesClose(true)
            Cookies.set('nisaFunctional', true, { expires: 30 })
            Cookies.set('nisaAnalytics', true, { expires: 30 })
          }}>
            X
          </button>
        </div>
        {
          analyticsCookies && <GA />
        }
      </>  
    );
} 