import FeaturedTitles from '../components/FeaturedTitles'
import GameCard from '../components/GameCard'
import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet'
import '../styles/pages/games.scss'

const Games = ({ games }) => {
    //SCROLL TO TOP ON LOAD
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [filtered, filterGames] = useState([])
    const [search, updateSearch] = useState('')
    const [genre, updateGenre] = useState('none')
    const [sort, changeSort] = useState('')
    const [genreList, updateList] = useState([])
    const [platform, updatePlatform] = useState('')
    const [viewAmount, increase] = useState(12)
    const viewable = filtered.slice(0, viewAmount)
    const platforms = [
        'Nintendo Switch™',
        'PC',
        'PlayStation®5',
        'PlayStation®4',
        'Xbox One',
        'Stadia',
        'PlayStation®3',
        'PlayStation®2',
        'PlayStation®Vita',
        'PlayStation Portable',
        'Nintendo 3DS',
        'Nintendo DS',
    ]

    const resetFilter = () => {
        updateSearch('')
        updatePlatform('')
        updateGenre('none')
        document.getElementById('Sort').value = ''
        document.getElementById('View').value = ''
        changeSort('')
        increase(12)
    }

    const sortNewestRelease = (a, b) => {
        const aReleases = a.releases
        const bReleases = b.releases

        const aPlatforms = Object.keys(aReleases?.NA || {})
        const bPlatforms = Object.keys(bReleases?.NA || {})

        const aString = aPlatforms.length > 0 ? aReleases.NA[aPlatforms[0]] : null
        const bString = bPlatforms.length > 0 ? bReleases.NA[bPlatforms[0]] : null
        
        const extractYear = (value) => {
            if (typeof value === 'string') {
                const match = value.match(/\b\d{4}\b/)
                if (match) {
                    return parseInt(match[0])
                }
            }
            return null
        }
        const year1 = extractYear(aString)
        const year2 = extractYear(bString)

        const priorityMap = {
            Early: 1,
            Spring: 2,
            Summer: 3,
            Mid: 4,
            Fall: 5,
            Late: 6,
            Winter: 7,
            Coming: 8,
        }

        const isAStringInPriorityMap = aString !== null && Object.keys(priorityMap).some((key) => aString.includes(key))
        const isBStringInPriorityMap = bString !== null && Object.keys(priorityMap).some((key) => bString.includes(key))

        if (isAStringInPriorityMap && isBStringInPriorityMap) {
            if (year1 === year2) {
                const season1 = aString !== null && aString.split(' ')[0]
                const season2 = bString !== null && bString.split(' ')[0]
                if (priorityMap[season1] > priorityMap[season2]) {
                    
                    return -1
                } else if (priorityMap[season1] < priorityMap[season2]) {
                    return 1
                }
            } else if (year1 > year2) {
                return -1
            } else if (year1 < year2) {
                return 1
            }
        } else if (!isAStringInPriorityMap && isBStringInPriorityMap) {
            return 1
        } else if (isAStringInPriorityMap && !isBStringInPriorityMap) {
            return -1
        } else if (!isAStringInPriorityMap && !isBStringInPriorityMap) {
            const aFirstDate = new Date(aString)
            const bFirstDate = new Date(bString)

            if (aFirstDate.getTime() === bFirstDate.getTime()) {
                return 0
            } else if (aFirstDate > bFirstDate) {
                return -1
            } else {
                return 1
            }
        }
    }
    const updateGenres = () => {
        let genres = []
        games.forEach((game) => {
            genres = [...new Set([...genres, ...game.genre])]
        })
        updateList(genres.sort())
    }

    const sortNewest = (a, b) => {
        if (a.publishDate && b.publishDate === undefined) {
            return -1
        } else if (a.publishDate === undefined && b.publishDate) {
            return 1
        } else if (a.publishDate > b.publishDate) {
            return -1
        } else if (a.publishDate < b.publishDate) {
            return 1
        }
        return 0
    }

    const sortOldest = (a, b) => {
        if (a.publishDate && b.publishDate === undefined) {
            return -1
        } else if (a.publishDate === undefined && b.publishDate) {
            return 1
        } else if (a.publishDate > b.publishDate) {
            return 1
        } else if (a.publishDate < b.publishDate) {
            return -1
        }
        return 0
    }

    const sortATOZ = (a, b) => {
        let textA = a.fullname.toUpperCase()
        let textB = b.fullname.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
    }

    const sortZTOA = (a, b) => {
        let textA = a.fullname.toUpperCase()
        let textB = b.fullname.toUpperCase()
        return textA < textB ? 1 : textA > textB ? -1 : 0
    }

    useEffect(() => {
        updateGenres()
        // filterGames(games)
        let genreFilter = genre === 'none' ? games : games.filter((game) => game.genre.includes(genre))
        let platformFilter = platform === '' ? games : games.filter((game) => game.platform.includes(platform))
        let searchFilter =
            search === '' ? games : games.filter((game) => game.fullname.toLowerCase().includes(search.toLowerCase()))
        let finalFilter =
            genreFilter.length && platformFilter.length && searchFilter.length
                ? genreFilter
                      .filter((game) => platformFilter.includes(game))
                      .filter((game) => searchFilter.includes(game))
                : []

        if (sort === 'increase') finalFilter.sort(sortNewest)
        else if (sort === 'decrease') finalFilter.sort(sortOldest)
        else if (sort === 'atoz') finalFilter.sort(sortATOZ)
        else if (sort === 'ztoa') finalFilter.sort(sortZTOA)
        else finalFilter.sort(sortNewestRelease)

        filterGames(finalFilter)
    }, [search, platform, genre, games, sort])

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='games-container'>
            <Helmet>
                <title>Games | NIS America, Inc.</title>
                <meta
                    name='description'
                    content='Learn more about the variety of games in the NIS America catalogue. Search by title, genre, or platform.'
                />
                <meta
                    property='og:description'
                    content='Learn more about the variety of games in the NIS America catalogue. Search by title, genre, or platform.'
                />
                <meta property='og:title' content='Games | NIS America, Inc.' />
                <meta property='og:image' content='%PUBLIC_URL%/img/ui/NIS_logo.png' />
                <meta property='og:image:url' content='%PUBLIC_URL%/img/ui/NIS_logo.png' />
            </Helmet>
            <div className='games-wrapper'>
                <FeaturedTitles games={games} />
                <h1 className='section-title'>ALL TITLES</h1>
                <motion.div
                    className='game-filter_container'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ delay: 1, duration: 0.75 }}
                >
                    <input
                        type='text'
                        className='search-bar'
                        value={search}
                        placeholder='Search our games, dood!'
                        onChange={(selection) => {
                            updateSearch(selection.target.value)
                        }}
                    />
                    <select
                        name='Genre'
                        id='genre-filter'
                        className='dropdown'
                        value={genre}
                        onChange={(selection) => {
                            updateGenre(selection.target.value)
                        }}
                    >
                        <option value='none' hidden selected>
                            Genre
                        </option>
                        {genreList.map((genre) =>
                            genre === '' ? (
                                <option value='none' hidden selected>
                                    Genre
                                </option>
                            ) : (
                                <option value={genre}>{genre}</option>
                            )
                        )}
                    </select>
                    <select
                        name='Platform'
                        id='Platform'
                        className='dropdown'
                        value={platform}
                        onChange={(selection) => {
                            updatePlatform(selection.target.value)
                        }}
                    >
                        <option value='' hidden selected>
                            Platform
                        </option>
                        {platforms.map((platform) => (
                            <option value={platform}>{platform}</option>
                        ))}
                    </select>
                    <select
                        name='Sort'
                        id='Sort'
                        className='dropdown'
                        onChange={(selection) => {
                            changeSort(selection.target.value)
                        }}
                    >
                        <option value='' hidden selected>
                            Sort By
                        </option>
                        <option value='increase'>Newest</option>
                        <option value='decrease'>Oldest</option>
                        <option value='atoz'>Alphabetical (A to Z)</option>
                        <option value='ztoa'>Alphabetical (Z to A)</option>
                    </select>
                    <select
                        name='View'
                        id='View'
                        className='dropdown items'
                        onChange={(selection) => {
                            increase(selection.target.value)
                        }}
                    >
                        <option value='' selected hidden>
                            Items Per Page
                        </option>
                        <option value={12}>12</option>
                        <option value={24}>24</option>
                        <option value={48}>48</option>
                        <option value={games.length}>ALL</option>
                    </select>
                    <button
                        className='reset-button'
                        onClick={() => {
                            resetFilter()
                        }}
                    >
                        RESET
                    </button>
                </motion.div>
                {filtered.length === 0 && <h1 className='empty-list'>No titles found</h1>}
                <div className='game-card-list'>
                    {viewable.length
                        ? viewable.map((game) => {
                              return <GameCard key={game._id} game={game} />
                          })
                        : games.filter(sortNewestRelease).map((game) => {
                              return <GameCard key={game._id} game={game} />
                          })}
                </div>

                {filtered.length - viewAmount > 0 && (
                    <motion.button
                        className='more-entries_button'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ delay: 1, duration: 0.75 }}
                        onClick={() => {
                            increase(viewAmount + 12)
                        }}
                    >
                        MORE GAMES
                    </motion.button>
                )}
            </div>
        </motion.div>
    )
}

export default Games
