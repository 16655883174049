import '../../styles/components/footer.scss'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {

    const [email, setEmail] = useState("")
    
    return (
        <div className='footer-container'>
            <section className='footer-social'>
                <h1>FOLLOW US, DOOD!</h1>
                <div className='footer-social_icons'>
                    <a
                        href='https://twitter.com/nisamerica'
                        target='_blank'
                        rel='noreferrer'
                        className='track-link'
                        data-label='Twitter'
                    >
                        <div className='icon-wrapper'>
                            <img width='60' height='63' src={`${process.env.PUBLIC_URL}/img/footer/new-twitter.svg`} alt='Twitter' />
                        </div>
                    </a>
                    <a
                        href='https://www.facebook.com/NISAmericaInc/'
                        target='_blank'
                        rel='noreferrer'
                        className='track-link'
                        data-label='Facebook'
                    >
                        <div className='icon-wrapper'>
                            <img width='60' height='63' src={`${process.env.PUBLIC_URL}/img/footer/facebook.svg`} alt='Facebook' />
                        </div>
                    </a>
                    <a
                        href='https://www.instagram.com/nisamerica/'
                        target='_blank'
                        rel='noreferrer'
                        className='track-link'
                        data-label='Instagram'
                    >
                        <div className='icon-wrapper'>
                            <img width='60' height='63' src={`${process.env.PUBLIC_URL}/img/footer/instagram.svg`} alt='Instagram' />
                        </div>
                    </a>
                    <a
                        href='https://www.youtube.com/user/NISAmerica'
                        target='_blank'
                        rel='noreferrer'
                        className='track-link'
                        data-label='YouTube'
                    >
                        <div className='icon-wrapper'>
                            <img width='60' height='63' src={`${process.env.PUBLIC_URL}/img/footer/youtube.svg`} alt='Youtube' />
                        </div>
                    </a>
                    <a
                        href='https://www.twitch.tv/nisamerica'
                        target='_blank'
                        rel='noreferrer'
                        className='track-link'
                        data-label='Twitch'
                    >
                        <div className='icon-wrapper'>
                            <img width='60' height='63' src={`${process.env.PUBLIC_URL}/img/footer/twitch.svg`} alt='Twitch' />
                        </div>
                    </a>
                    <a
                        href='https://discord.gg/nisa'
                        target='_blank'
                        rel='noreferrer'
                        className='track-link'
                        data-label='Discord'
                    >
                        <div className='icon-wrapper'>
                            <img width='60' height='63' src={`${process.env.PUBLIC_URL}/img/footer/discord.svg`} alt='Discord' />
                        </div>
                    </a>
                </div>
            </section>
            <section className='footer-mailing-list'>
                <h1 className='subscribe-footer'>SUBSCRIBE TO OUR MAILING LIST TO GET THE LATEST INFORMATION!</h1>
                <div className='mailchimp'>
                    <form
                        action='https://nisamerica.us11.list-manage.com/subscribe/post?u=e8fc423eff95099d1ffcc6009&amp;id=349f7d20f9'
                        method='post'
                        id='mc-embedded-subscribe-form'
                        name='mc-embedded-subscribe-form'
                        className='validate'
                        target='_blank'
                        noValidate=''
                    >
                        <div id='mc_embed_signup_scroll' className='mailchimp'>
                            <div className='interact'>
                                <svg
                                    aria-hidden='true'
                                    focusable='false'
                                    data-prefix='far'
                                    data-icon='envelope'
                                    class='svg-inline--fa fa-envelope'
                                    role='img'
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 512 512'
                                >
                                    <path
                                        fill='currentColor'
                                        d='M448 64H64C28.65 64 0 92.65 0 128v256c0 35.35 28.65 64 64 64h384c35.35 0 64-28.65 64-64V128C512 92.65 483.3 64 448 64zM64 112h384c8.822 0 16 7.178 16 16v22.16l-166.8 138.1c-23.19 19.28-59.34 19.27-82.47 .0156L48 150.2V128C48 119.2 55.18 112 64 112zM448 400H64c-8.822 0-16-7.178-16-16V212.7l136.1 113.4C204.3 342.8 229.8 352 256 352s51.75-9.188 71.97-25.98L464 212.7V384C464 392.8 456.8 400 448 400z'
                                    ></path>
                                </svg>
                                <input
                                    type='email'
                                    value={email}
                                    name='EMAIL'
                                    className='email'
                                    id='mce-EMAIL'
                                    placeHolder='Enter email address'
                                    required=''
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <label for='mce-EMAIL'>Enter your email!</label>
                                <div style={{ position: ' absolute', left: '-5000px' }}>
                                    <input
                                        type='text'
                                        id='text'
                                        name='b_e8fc423eff95099d1ffcc6009_349f7d20f9'
                                        tabindex='-1'
                                        value=''
                                    />
                                    <label for='text'>Text</label>
                                </div>
                                <input type='hidden' value='footer' name='SIGNSITE' className='' id='mce-SIGNSITE' />
                                <label for='mce-SIGNSITE'>Hidden</label>
                                <div className='clear'>
                                    <input
                                        type='submit'
                                        value='SUBSCRIBE'
                                        name='subscribe'
                                        id='mc-embedded-subscribe'
                                        className='button'
                                    />
                                    <label for='mc-embedded-subscribe'>Submit</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <section className='trademarks'>
                <img width='100%' height='100%' src={`${process.env.PUBLIC_URL}/img/ui/NIS_logo.webp`} alt='NISA Logo' />
                <div className='copyright'>
                    <h2>© NIS America, Inc. All Rights Reserved.</h2>
                    <h2>All other trademarks are properties of their respective owners.</h2>
                </div>
                <div className='footer-links'>
                    <Link to='/careers'>Careers</Link> |<Link to='/company'>Company</Link> |
                    <a href='https://store.nisamerica.com/contact-us'>Support</a> |
                    <a href='https://mailchi.mp/nisamerica/nis-america-press'>Press</a> |
                    <Link to='/privacy-policy'>Privacy Policy</Link> |<Link to='/terms-of-use'>Terms of Use</Link> |
                    <Link to='/faq'>FAQ</Link>
                </div>
            </section>
        </div>
    )
}

export default Footer