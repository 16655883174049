import '../../styles/pages/etc.scss';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Careers = ({careers}) => {
    return (
    <div className="etc-container">
        <Helmet>
            <title>Careers | NIS America, Inc.</title>
        </Helmet>
        <div className="etc-wrapper">
            <h1 className="title temp">
                Careers
            </h1>
            <p className='career-description'>
                {
                    /*
                        <span style={{color: 'yellow'}}>
                            *Please be advised that the careers section of our website is currently undergoing maintenance to improve your experience. We apologize for any inconvenience this may cause. Rest assured that we are working diligently to complete the updates, and the careers section will return to normal function as soon as possible.
                        </span>
                    */
                }

                        Please read the job description and the qualifications carefully then send your resume to us using the forms within each page. When applying for Game Tester positions, be sure to include your available working hours and earliest start date.
            </p>
            <div className="careers-list">
            {
                careers.map((career)=>(
                    <Link to={`/careers/${career.url}`} className="career-link">{career.title}</Link>
                ))
            }
            </div>
        </div>
    </div>
    )
}

export default Careers;