import { Helmet } from 'react-helmet';
import '../../styles/pages/etc.scss'

const Terms = () => (
    <div className="etc-container">
        <Helmet>
            <title>Terms of Use | NIS America, Inc.</title>
        </Helmet>
        <div className="etc-wrapper">
            <h1 className="title">Terms of Use</h1>
            <h1 className="header">NIS America Website Terms of Use </h1>
            <h1 className="top spacer">NIS America Account </h1>
            <p>An NIS America members Account ("Account") may be required to access and use some NIS America Services. If you have questions about Account registration, please contact us by e-mail at support@nisamerica.com. To create an NIS America Account, you must have an email address, and provide truthful and accurate information. You must be eligible to use the NIS America Service for which you are registering. Some NIS America Services may require creation of a "user name". User names are tied to your Account. You may not use a user name that is used by someone else, is vulgar or offensive, or otherwise violates the Terms of Service. You are solely responsible for all activity on your Account. Your Account may be terminated if someone else uses it to engage in activity that violates the Terms of Service or is otherwise improper or illegal. You should not reveal your Account password to others. NIS America will not ask you to reveal your password.</p>
            <h1 className="top spacer">Copyright</h1>
            <p>All copyrighted and copyrightable materials on this Site, including, without limitation, the NIS America logo, NIS America designs, text, graphics, pictures, software, sound files and other files, and the selection and arrangement thereof ("Materials") are ALL RIGHTS RESERVED Copyright NIS America, Inc. and/or its licensors. Except as stated herein, none of the Materials may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, without the prior written permission of NIS America or the respective copyright owner. Permission is granted to display, copy, distribute and download the Materials on this Site for your personal use, non-commercial use, and informational use only; provided that, you may not, without the permission of NIS America or the respective copyright owner, (a) copy, publish, or post any Materials on any computer network or broadcast or publications media, (b) modify the Materials, or (c) remove or alter any copyright and other proprietary notices contained in the Materials.</p>
            <h1 className="top spacer">Content</h1>
            <p>This Site includes discussion forums and interactive areas, including bulletin board services ("BBS"). The forums and interactive areas shall be used only for non-commercial purposes. By using the forums you agree not to do any of the following:</p>
            <ul className='unordered spacer'>
                <li>Upload to, distribute or otherwise publish through this Site any message, data, information, text or other material that is unlawful, libelous, defamatory, obscene, pornographic, indecent, lewd, harassing, threatening, harmful, invasive of privacy or publicity rights, abusive, inflammatory or otherwise objectionable.</li>
                <li>Upload or transmit any Content that would constitute or encourage a criminal offense, violate the rights of any party, or that would otherwise create liability or violate any local, state, national, or international law.</li>
                <li>Upload or transmit any Content that may infringe any patent, trademark, trade secret, copyright or other intellectual or proprietary right of any party. By posting any Content, you represent and warrant that you have the lawful right to distribute and reproduce such Content;</li>
            </ul>
            <p>NIS America respects the intellectual property rights of others. You must have the legal right to upload Content to nisamerica.com. You may not upload or post any Content on nisamerica.com that is protected by copyright, trademark or other intellectual property rights unless (i) you are the owner of all of those rights; or (ii) you have the prior written consent of the owner(s) of those rights to make such use of that Content. NIS America may, without prior notice to you and in its sole judgment, remove Content that may infringe the intellectual property rights of a third party. If you are a repeat infringer of NIS America's or a third party's intellectual property rights, NIS America may terminate your Account without notice to you. </p>
            <h1 className="top spacer">Responsibility</h1>
            <p>You shall remain solely liable for the Content of any messages or other information you upload or transmit to this Site, including the discussion forums or interactive areas of the Site. You agree to indemnify and hold harmless NIS America from any claim, action, demand, loss, or damages (including attorneys' fees) made or incurred by any third-party arising out of or relating to your conduct, your violation of these Site Terms, or your violation of any rights of a third-party.</p>
            <h1 className="top spacer">DISCLAIMERS</h1>
            <p>"AS IS" BASIS. THIS SITE AND THE MATERIALS CONTAINED HEREIN ARE PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. YOU EXPRESSLY AGREE THAT USE OF THIS SITE, INCLUDING ALL CONTENT, DATA OR SOFTWARE DISTRIBUTED BY, DOWNLOADED OR ACCESSED FROM OR THROUGH THIS SITE, IS AT YOUR SOLE RISK.</p>
            <h1 className="top spacer">Limitation of Liability</h1>
            <p>IN NO EVENT SHALL NIS AMERICA BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS, OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE), OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF THIS SITE OR THE MATERIALS CONTAINED IN, OR ACCESSED THROUGH, THIS SITE.</p>
        </div>
    </div>
)

export default Terms;