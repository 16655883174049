import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { useState } from "react";
import ReactGA from 'react-ga4'
import '../../styles/components/navigation.scss'

const Navigation = () => {

    const [isOpen, setOpen] = useState(false)

    const top = {
        open: { y: '9px', rotate: 45},
        closed: { y: 0}
    }
    
    const middle = {
        open: { opacity: 0 , transition: {duration: .1}},
        closed: { opacity: 1}
    }
    
    const bottom = {
        open: { y: '-9px', rotate: -45},
        closed: { y: 0}
    }

    return (
        <div className='nav-wrapper'>
            <nav className='nav-bar'>
                <NavLink exact activeClassName='nav-link-active' to='/' className='nav-link'>
                    Home
                </NavLink>
                <NavLink activeClassName='nav-link-active' to='/games' className='nav-link'>
                    Games
                </NavLink>
                <NavLink to='/'>
                    <img
                        width='100%'
                        height='100%'
                        src={`${process.env.PUBLIC_URL}/img/ui/NIS_logo.webp`}
                        alt='NISA-logo'
                        className='logo'
                    />
                </NavLink>
                <NavLink activeClassName='nav-link-active' to='/blog' className='nav-link'>
                    News
                </NavLink>
                <div className='nav-link nav-link_store'>
                    <h1>Store</h1>
                    <div className='store-dropdown'>
                        <a
                            href='https://store.nisamerica.com/'
                            onClick={() => {
                                ReactGA.event({
                                    category: 'Navigation Link',
                                    action: 'Nav',
                                    label: 'Store - NA',
                                })
                            }}
                        >
                            North America
                        </a>
                        <a
                            href='https://store.nisaeurope.com/'
                            onClick={() => {
                                ReactGA.event({
                                    category: 'Navigation Link',
                                    action: 'Nav',
                                    label: 'Store - EU',
                                })
                            }}
                        >
                            Europe
                        </a>
                    </div>
                </div>
            </nav>
            <div className={`nav-mobile ${isOpen ? 'mobile-open' : false}`}>
                <div className='nav-top'>
                    <NavLink to='/'>
                        <img
                            width='100%'
                            height='100%'
                            src={`${process.env.PUBLIC_URL}/img/ui/NIS_logo.webp`}
                            alt='NISA-logo'
                            className='logo'
                        />
                    </NavLink>
                    <div className='hamburger-container' onClick={() => setOpen(!isOpen)}>
                        <div className='hamburger'>
                            <motion.div
                                className='ham'
                                animate={isOpen ? 'open' : 'closed'}
                                variants={top}
                            ></motion.div>
                            <motion.div
                                className='ham'
                                animate={isOpen ? 'open' : 'closed'}
                                variants={middle}
                            ></motion.div>
                            <motion.div
                                className='ham'
                                animate={isOpen ? 'open' : 'closed'}
                                variants={bottom}
                            ></motion.div>
                        </div>
                    </div>
                </div>
                <div className={`nav-list ${isOpen ? 'list-open' : 'list-closed'}`}>
                    <NavLink
                        exact
                        activeClassName='nav-link-active'
                        to='/'
                        className='nav-link'
                        onClick={() => setOpen(!isOpen)}
                    >
                        Home
                    </NavLink>
                    <NavLink
                        activeClassName='nav-link-active'
                        to='/games'
                        className='nav-link'
                        onClick={() => setOpen(!isOpen)}
                    >
                        Games
                    </NavLink>
                    <NavLink
                        activeClassName='nav-link-active'
                        to='/blog'
                        className='nav-link'
                        onClick={() => setOpen(!isOpen)}
                    >
                        News
                    </NavLink>
                    <a href='https://store.nisamerica.com/' className='nav-link'>
                        Store - North America
                    </a>
                    <a href='https://store.nisaeurope.com/' className='nav-link'>
                        Store - Europe
                    </a>
                </div>
            </div>
        </div>
    )
};

export default Navigation;