import '../../styles/app.scss'

const RightArrow = ({className, style, onClick, resetSlide}) => (
    <div className="arrow-container_right">
        <img src="/img/ui/arrow-right.png" alt="Right Arrow" className={`right-arrow ${className}`} style={{...style}} onClick={()=>{
            onClick()
            resetSlide()
        }}/>
    </div>
)

export default RightArrow;