import '../../styles/pages/etc.scss'
import { useState } from 'react'
import axios from 'axios'
const Apply = ({title, sendTo}) => {

    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [subject, setSubject] = useState(null)
    const [message, setMessage] = useState(null)
    const [file, setFile] = useState(null)
    const [success, showSuccess] = useState(false)
    const [error, showError] = useState(false)
    const [submit, showSubmit] = useState(true)

    const sendEmail = (event) => {
        event.preventDefault()

        let bodyText = `
        Submitted on: ${new Date()}
        Submitted values are:

        Position: ${title}
        Name: ${name}
        Email Address: ${email}
        Subject: ${subject}
        Message:
        ${message}
        
        ${file !== null ? "Resume Attached Below" : "No Resume Attached"}
        Apply To: ${sendTo}
        `

        let formData = new FormData();
        formData.append('name', name)
        formData.append('position', title)
        formData.append('to', sendTo)
        formData.append('email', email)
        formData.append('subject', `CAREER SUBMISSION (${title}) | ${name}`)
        formData.append('body', bodyText)
        formData.append('file', file)

        axios({
            method: "post",
            url: "https://nisamerica.com/emailer/career",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(function (response) {
                if(response.data.success){
                    showSuccess(true)
                    showSubmit(true)
                }
            })
            .catch(function (response) {
                if(!response.data.success){
                    showError(true)
                    showSubmit(false)
                }
        });
    }

    const handleFileSelect = (event) => {
        setFile(event.target.files[0])
    }


    return (
        <div className="application-container">
            <div className={`shadow-box ${success ? "visible" : "invisible"}`}>
                <div className="shadow-textbox">
                    <h1>Your application has been successfully submitted!</h1>
                    <button className='close-shadowbox' onClick={()=>{showSuccess(false)}}>Close</button>
                </div>
            </div>
            <div className={`shadow-box ${error ? "visible" : "invisible"}`}>
                <div className="shadow-textbox">
                    <h1>There was an error processing your application</h1>
                    <button className='close-shadowbox' onClick={()=>{showError(false)}}>Close</button>
                </div>
            </div>
            <h1 className='app-title'>APPLY</h1>
            <h1 className="header">{title}</h1>
            <form action="post" onSubmit={(e)=>{
                sendEmail(e)
            }}>
            <div className="input-wrapper">
                <input type="text" required placeholder='Name' className='single-line' onChange={e=>setName(e.target.value)}/>
                <input type="email" required placeholder='Email' className='single-line' onChange={e=>setEmail(e.target.value)}/>
                <input type="text" required placeholder='Subject' className='single-line' onChange={e=>setSubject(e.target.value)}/>
                <textarea type="text" required placeholder='Message' className='paragraph' onChange={e=>setMessage(e.target.value)}/>
            </div>
            <div className="file-input">
                <h1>Upload Resume *</h1>
                <input required className="file-upload" type="file" accept=".gif, .jpg, .jpeg, .png, .txt, .pdf, .doc, .docx" onChange={handleFileSelect}/>
                <h1>Files must be less than 2 MB.</h1>
                <h1>Allowed file types: gif, jpg, jpeg, png, txt, pdf, doc, docx.</h1>
                {
                    submit && <input className="submit-input" type="submit" value="Submit"/>
                }
            </div>
            </form>
        </div>
    )
}

export default Apply;