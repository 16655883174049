import '../../styles/pages/etc.scss'
import { Helmet } from 'react-helmet';

const Company = () => (
    <div className="etc-container">
            <Helmet>
                <title>Company | NIS America, Inc.</title>
            </Helmet>
            <div className="etc-wrapper">
            <h1 className="title">
                Company
            </h1>
            <h1 className="header">
                About NIS America, Inc.
            </h1>
            <div className="wrapper">
                <p>
                    NIS America, Inc. is a subsidiary of Nippon Ichi Software, Inc. of Japan, which established its North American operations on December 24, 2003. Based in Santa Ana, California, NIS America, Inc. handles operations including localization, marketing, and publishing of Nippon Ichi titles.
                </p>
                <p>
                    Nippon Ichi Software, Inc. was founded in 1994 in Gifu, Japan, as an entertainment company. Since then Nippon Ichi Software, Inc. has grown into an international company focused on creating innovative video game products.
                </p>
                <p>
                    NIS America, Inc. is committed to marketing the most innovative and creative titles in the industry. We at NIS America, Inc. will continue to work hard to bring exciting new titles to satisfy both our fans and new users.
                </p>
            </div>
        </div>
    </div>
)

export default Company;