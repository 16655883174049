import FeaturedCard from "./FeaturedCard";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import axios from "axios";
import "../styles/components/feature.scss";

const FeaturedTitles = ({ games }) => {
  //GET SCREENSHOTS
  const [featured, setFeatured] = useState([]);
  useEffect(() => {
    axios
      .get(`https://www.nisamerica.com/api/datajson`)
      .then((response) => {
        const featTitles = response.data.data["featured"];
        setFeatured(featTitles);
      })
      .catch((error) => {
        console.log("An error has occured");
        console.log(error);
      });
  }, []);

  return (
    <motion.div
      className="featured-container"
      initial={{ opacity: 0, y: "-20px" }}
      animate={{ opacity: 1, y: "0px" }}
      transition={{ duration: 0.75 }}
    >
      <motion.h1
        className="featured_title"
        initial={{ opacity: 0, y: "-20px" }}
        animate={{ opacity: 1, y: "0px" }}
        transition={{ duration: 0.75 }}
      >
        FEATURED TITLES
      </motion.h1>
      <motion.div
        className="cards-list"
        initial={{ opacity: 0, y: "-20px" }}
        animate={{ opacity: 1, y: "0px" }}
        transition={{ delay: 0.5, duration: 0.75 }}
      >
        {featured &&
          featured.map((name, index) => {
            return games.map((game) => {
              if (name === game.pathname && index < 4) return <FeaturedCard game={game} />;
              return false;
            });
          })}
      </motion.div>
    </motion.div>
  );
};

export default FeaturedTitles;
