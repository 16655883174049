import '../styles/pages/agegate.scss'
import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

const AgeGate = () => {

    //SCROLL TO TOP ON LOAD
    useEffect(() => {
        window.scrollTo(0,0)
    }, []);
    
    const currentDate = new Date()   
    const [month, setMonth] = useState("January");
    const [day, setDay] = useState(1);
    const [year, setYear] = useState(currentDate.getFullYear())

    const getAge = (birthdate) => {
        const today = new Date()

        let age = today.getFullYear() - birthdate.getFullYear();
        let m = today.getMonth() - birthdate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
            age--;
        }

        return age;
    }
    const setAge = () => {
        localStorage.setItem('age', getAge(new Date(`${month} ${day}, ${year}`)))
        localStorage.setItem('ageTimestamp', new Date())
    }

    let navigate = useNavigate();
    const goToGame = () => {
        let gamePath = `/games/${localStorage.getItem('recentGame')}`
        if(localStorage.getItem('recentGame') === '25thward-official') {
            window.location.replace("https://nisamerica.com/25thward/");
        }
        navigate(gamePath, {replace: true})
    }
    const goHome = () => {
        let path = '/'
        navigate(path, {replace: true})
    }
 
    return(
        <div className="age-container" id='top' onClick={window.scrollTo(0,0)}>
            <Helmet>
                <title>Age Gate | NIS America, Inc.</title>
            </Helmet>
            <h1 className='age-text'>Please verify your age:</h1>
            <div className="age-input">
                <select name="month" onChange={(e)=>{
                    setMonth(e.target.value)
                }}>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                </select>
                <select name="day" value={day} onChange={(e)=>{
                    setDay(e.target.value)
                }}>
                    {
                        [...Array(31)].map((e,i)=>(
                            <option key={`day-${i}`} value={i+1}>{i+1}</option>
                        ))
                    }
                </select>
                <select name="year" value={year} onChange={
                    (e)=>{
                        setYear(e.target.value)
                    }
                }>
                    {
                        [...Array(100)].map((e,i)=>(
                            <option key={`month-${i}`} value={currentDate.getFullYear()-i}>{+currentDate.getFullYear()-i}</option>
                        ))
                    }
                </select>
            </div>
            <button className="age-submit" onClick={()=>{
                setAge()
                if(localStorage.getItem('age')>17) {
                    goToGame()
                } else {
                    goHome()
                }
                
            }}>Submit</button>
        </div>
    )
}

export default AgeGate; 