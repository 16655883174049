import { useState, useEffect } from 'react'
import axios from 'axios'
import ReactGA from 'react-ga4'
import { Link } from 'react-router-dom'

const TopHero = ({ games }) => {
    const [featured, setFeatured] = useState([])

    useEffect(() => {
        axios
            .get(`https://www.nisamerica.com/api/datajson`)
            .then((response) => {
                const featTitles = response.data.data['featured']
                setFeatured(featTitles)
            })
            .catch((error) => {
                console.log('An error has occured')
                console.log(error)
            })
    }, [])

    const [currentSlide, changeCurrentSlide] = useState(0)
    const [intervalID, changeInterval] = useState(null)

    const eventTrack = (category, action, label) => {
        ReactGA.event({
            category: category,
            action: action,
            label: label,
        })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            changeCurrentSlide((currentSlide) => currentSlide + 1)
        }, 5000)
        changeInterval(interval)
        return () => clearInterval(interval)
    }, [])

    return (
        <div className='top-hero'>
            {featured &&
                featured.map((name, gameIndex) => {
                    return games.map((game) => {
                        if (name === game.pathname)
                            return (
                                <div
                                    key={gameIndex}
                                    className={`top-hero_slide ${
                                        currentSlide % featured.length === gameIndex ? 'visible' : 'invisible'
                                    }`}
                                >
                                    <div className='top-hero_contents'>
                                        <div className='top-hero_contents-wrapper'>
                                            <h1 className={`top-hero_title ${game.fullname.length > 50 && 'longname'}`}>
                                                {game.fullname}
                                            </h1>
                                            <div className='top-hero_links'>
                                                
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}/games/${game.pathname}/#preorder`}
                                                    >
                                                        <button
                                                            className='top-hero_preorder-button'
                                                            onClick={() => {
                                                                eventTrack(
                                                                    'Store Link',
                                                                    'Top Page - Hero',
                                                                    game.fullname
                                                                )
                                                            }}
                                                        >
                                                            {game.hasReleased ? 'BUY NOW' : 'PREORDER NOW'}
                                                        </button>
                                                    </Link>
                                               
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/games/${game.pathname}`}
                                                    className='learn-more'
                                                >
                                                    LEARN MORE
                                                </Link>
                                            </div>
                                            <div className='top-hero_dots'>
                                                {featured.map((game, index) => (
                                                    <div
                                                        key={`circle-${index}`}
                                                        className={`top-circle ${
                                                            currentSlide % featured.length === index
                                                                ? 'top-active'
                                                                : false
                                                        }`}
                                                        onClick={() => {
                                                            changeCurrentSlide(index)
                                                            clearInterval(intervalID)
                                                        }}
                                                    ></div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='top-hero_background'>
                                        {' '}
                                        <picture>
                                            {/* WebP format */}
                                            <source
                                                media='(max-width: 430px)'
                                                srcSet={`https://www.nisamerica.com/public/assets/${game.pathname}/background.webp`}
                                                type='image/webp'
                                            />
                                            <source
                                                media='(max-width: 1024px)'
                                                srcSet={`https://www.nisamerica.com/public/assets/${game.pathname}/background.webp`}
                                                type='image/webp'
                                            />
                                            <source
                                                srcSet={`https://www.nisamerica.com/public/assets/${game.pathname}/background.webp`}
                                                type='image/webp'
                                            />
                                            {/* PNG format */}
                                            <img
                                                srcSet={`https://www.nisamerica.com/public/assets/${game.pathname}/background.webp 430w, https://www.nisamerica.com/public/assets/${game.pathname}/background.webp 1024w, https://www.nisamerica.com/public/assets/${game.pathname}/background.webp 1920w`}
                                                alt='Background'
                                                width='100%'
                                                height='100%'
                                                style={{
                                                    objectFit: 'cover',
                                                    objectPosition: 'center',
                                                }}
                                            />
                                        </picture>
                                    </div>
                                </div>
                            )
                        return false
                    })
                })}
        </div>
    )
}

export default TopHero
