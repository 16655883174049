import '../styles/components/esrb.scss'

const ESRB = ({rating, lines, bottom}) => {

    if(rating==="RP - E, E10+" || rating === "RP") {
        return (
            <div className="pending-container">
                <img src={`${process.env.PUBLIC_URL}/img/esrb/RP.svg`} alt="Rating Pending" />
            </div>
        )
    }
    else if(rating==="RP - T, M, AO" || rating==="RP_TMO"){
        return (
            <div className="pending-container">
                <img src={`${process.env.PUBLIC_URL}/img/esrb/RP_TMAO.svg`} alt="Rating Pending" />
            </div>
        )
    }
    else if(rating==="RP - Likely Mature 17+"){
        return (
            <div className="pending-container">
                <img src={`${process.env.PUBLIC_URL}/img/esrb/RP_17+.svg`} alt="Rating Pending" />
            </div>
        )
    }
    else if(rating==="legion"){
        return (
            <div className="pending-container">
                <img style={{maxWidth: '220px'}} src="https://nisamerica.com/public/assets/logo/flc-rating.png" alt="E10-T" />
            </div>
        )
    }
    else {
        return(
                rating.length > 0 && 
                <div className="esrb-container">
                    {
                        rating.toUpperCase() !== 'T-M' ? 
                            <div className="esrb-top">
                                <img src={`${process.env.PUBLIC_URL}/img/esrb/${rating.toUpperCase()}.svg`} alt={rating} />
                                <div className="text-container">
                                {
                                        lines.map((line, lineIndex) => (
                                            <div className="line" key={lineIndex}>
                                                {line}
                                            </div>
                                        ))
                                }
                                </div>
                            </div> :
                            <div className="esrb-long-top">
                                <img src={`${process.env.PUBLIC_URL}/img/esrb/${rating.toUpperCase()}.svg`} alt={rating} />
                                <div className="text-container">
                                {
                                        lines.map((line, lineIndex) => (
                                            <div className="line" key={lineIndex}>
                                                {line}
                                            </div>
                                        ))
                                }
                                </div>
                            </div>
            
                    }
                    {
                        bottom[0] && bottom[0].length > 0 && 
                        <div className="esrb-bottom">
                        {
                            bottom.map((text, index)=>(
                                <h1 key={index}className="bottom-text">
                                    {text}
                                </h1>
                            ))
                        }
                        </div>
                    }
                </div>
            )
    }
}

export default ESRB;