import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";
import Home from "./pages/Home";
import Games from "./pages/Games";
import GamePage from "./pages/GamePage";
import News from "./pages/News.js";
import ArticlePage from "./pages/ArticlePage";
import ErrorPage from "./pages/ErrorPage";
import Login from "./pages/Login";
import Navigation from "./components/ui/Navigation";
import Footer from "./components/ui/Footer";
import Careers from "./pages/other/Careers";
import CareerPage from "./pages/careers/CareerPage";
import Company from "./pages/other/Company";
import Privacy from "./pages/other/Privacy";
import Terms from "./pages/other/Terms";
import AgeGate from "./pages/AgeGate";
import ScrollToTop from "./components/utility/ScrollToTop";
import Contests from "./pages/other/Contest";
import Giveaways from "./pages/other/Giveaways";
import FAQ from "./pages/other/FAQ";
import "./styles/app.scss";
import "react-quill/dist/quill.snow.css";

import Loading from "./components/Loading";
import Cookie from "./components/Cookie.js";

function App() {
  //commit test

  const [isLoading, setLoading] = useState(true);
  const [games, setGames] = useState([]);
  const [news, setNews] = useState([]);
  const [totalArticles, setTotalArticles]  = useState(0)
  const [careers, setCareers] = useState([]);
  const [platform, setPlatform] = useState("");
  const [region, setRegion] = useState("");

  const [token, setToken] = useState(false)
  const setTokenWrapper = val => {
    setToken(val)
  }

  const changePlatform = (platform) => {
    localStorage.setItem("platform", platform);
    setPlatform(localStorage.getItem("platform"));
  };
  const changeRegion = (region) => {
    localStorage.setItem("region", region);
    setRegion(localStorage.getItem("region"));
  };

  //GAMES, NEWS, & CAREERS API REQUEST
  const fetchRequests = async () => {
    //CHECK IF USER IS LOGGED IN
    if(localStorage.getItem("login_token") !== null) {
      setToken(true)
      //IF LOGGED IN GET HIDDEN GAMES
      if(token) {
        axios
        .get(`https://nisamerica.com/api/games-private/${localStorage.getItem("login_token")}`)
        .then((response) => {
          const gameData = response.data.games;
          setGames(gameData);
        })
        .catch((error) => {
          console.log("An error has occured");
          console.log(error);
          axios
          .get("https://www.nisamerica.com/api/games")
          .then((response) => {
            const gameData = response.data.games;
            setGames(gameData);
            
          })
          .catch((error) => {
            console.log("An error has occured");
            console.log(error);
          }); 
        });

        axios
        .get(`https://nisamerica.com/api/blogs-private/${localStorage.getItem("login_token")}`)
        .then((response) => {
          console.log(response)
          const newsData = response.data.blogs;
          setNews(newsData);
        })
        .catch((error) => {
          console.log("An error has occured");
          console.log(error);
          axios
          .get("https://nisamerica.com/api/test/blogs")
          .then((response) => {
            const newsData = response.data;
            setNews(newsData.blogs);
            setTotalArticles(newsData.length)
          })
          .catch((error) => {
            console.log(error);
          });
        });
      }
    }
    else {
      axios
      .get("https://nisamerica.com/api/test/blogs")
      .then((response) => {
        const newsData = response.data;
        setNews(newsData.blogs);
        setTotalArticles(newsData.length)
      })
      .catch((error) => {
        console.log(error);
      });
    }
    
    //GET PUBLIC GAMES
    axios
    .get("https://www.nisamerica.com/api/games")
    .then((response) => {
      const gameData = response.data.games;
      setGames(gameData);
    })
    .catch((error) => {
      console.log("An error has occured");
      console.log(error);
    });

    axios
    .get("https://nisamerica.com/api/test/blogs")
    .then((response) => {
      const newsData = response.data;
      setNews(newsData.blogs);
      setTotalArticles(newsData.blogs.length)
    })
    .catch((error) => {
      console.log(error);
    });
    

    axios
    .get(`https://nisamerica.com/api/careers`)
    .then((response) => {
    const careerList = response.data.careers;
    setCareers(careerList);
    })
    .catch((error) => {
    console.log("An error has occured");
    console.log(error);
    });
    
    setTimeout(() => setLoading(false), 250)
  }

  useEffect(() => {
    fetchRequests().catch(console.error)
  }, [token]);

  if (isLoading) {
    return <div className="App"><Loading/></div>;
  }

  return (
    <AnimatePresence>
      <motion.div
        className="App"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.75 }}
      >
        <Router basename={process.env.PUBLIC_URL}>
          <Navigation />
          <ScrollToTop />
          <div className="app-wrapper">
            <Routes>
              <Route
                exact
                path="/"
                element={games && news && <Home games={games} news={news} />}
              />
              <Route
                exact
                path="/games"
                element={games && <Games games={games} />}
              />
              {games && !isLoading &&
                games.map((game) => {
                  return (
                    <Route
                      key={game._id}
                      path={`${process.env.PUBLIC_URL}/games/${game.pathname}`}
                      index
                      element={
                        <GamePage
                          game={game}
                          games={games}
                          platform={platform}
                          changePlatform={changePlatform}
                          region={region}
                          changeRegion={changeRegion}
                          token={token}
                        />
                      }
                    />
                  );
                })}
              <Route
                exact
                path="/blog"
                component={News}
                element={news && games && totalArticles && <News news={news} totalArticles={totalArticles}/>}
              />
              {news &&
                news.map((article) => {
                  return (
                    <Route
                      key={article._id}
                      path={`${process.env.PUBLIC_URL}/blog/${article.url}`}
                      index
                      element={
                        <ArticlePage
                          article={article}
                          articles={news}
                          games={games}
                        />
                      }
                    />
                  );
                })}
              <Route exact path="/careers" element={<Careers careers={careers}/>} />
              {careers &&
                  careers.map((career) => {
                  return (
                    <Route
                        key={career._id}
                        path={`${process.env.PUBLIC_URL}/careers/${career.url}`}
                        index
                        element={
                            <CareerPage career={career}/>
                        }
                    />
                  );
              })}
              <Route exact path="/company" element={<Company />} />
              <Route exact path="/privacy-policy" element={<Privacy />} />
              <Route exact path="/terms-of-use" element={<Terms />} />
              <Route exact path="/FAQ" element={<FAQ />} />
              <Route exact path="/age" element={<AgeGate />} />
              <Route exact path="/contests" element={<Contests/>} />
              <Route exact path="/contest" element={<Navigate to="/contests"/>} />
              <Route exact path="/giveaways" element={<Giveaways/>} />
              <Route exact path="/nislogin" element={<Login token={token} setToken={setTokenWrapper}/>} />
              <Route exact path="/games-us" element={<div className="games-container"><Navigate to="/games"/></div>}/>
              <Route path="/404" element={<ErrorPage/>} />
              { games && news && games.length > 1 && news.length > 1 && careers.length > 1 && <Route path="*" element={<Navigate replace to="/404" />} /> }
            </Routes>
          </div>
          <Cookie/>
          <Footer />
          {
              token && <button className="home-button" style={{marginBottom: '50px'}}
              onClick={()=>{
                localStorage.removeItem("login_token")
                setToken(false)
              }}
            >
              <h1>LOGOUT</h1>
            </button>
          }
        </Router>
      </motion.div>
    </AnimatePresence>
  );
}

export default App;
