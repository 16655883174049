import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import { useEffect, useState } from "react";
import "../styles/components/articlecard.scss";

const ArticleCard = ({ article, index }) => {

  const [blog, setBlog] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, [article]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://nisamerica.com/api/test/blog/${article}`);
      setBlog(response.data.blog);
    } catch (error) {
      setError(error.message);
    }
  };

  const extractSrcFromHTML = (htmlString) => {
    const srcRegex = /<img\s+[^>]*src="([^"]*)"[^>]*>/i;
    const match = htmlString.match(srcRegex);
  
    if (match && match[1]) {
      return match[1];
    }
  
    return null;
  };

  if(blog && blog.external.length > 10) {
    return (
        <a className="article-card_link" href={blog.external} target="_blank" rel="noopener noreferrer" key={index}>
          <motion.div
          className={`article-card_container ${blog.hidePage === true && "hidden-blog"}`}
          style={{
            backgroundImage: `${blog.thumbnail ? `url(${extractSrcFromHTML(blog.thumbnail)})` : `url(https://www.nisamerica.com/public/blogs/${blog.url}/image.webp) , url(${process.env.PUBLIC_URL}/img/articles/${blog.type}.webp)`}`
          }}
          initial={{ opacity: 0, y: "-20px" }}
          animate={{ opacity: 1, y: "0px" }}
        >
          <div className="article-card_wrapper">
            <div className="article-card_spacing">
              <div className={`article-card_type ${blog.type}`}>
                {blog.type}
              </div> 
              <section className="article-card_info">
                <h1>{blog.title}</h1>
                {
                  blog.summary.length > 5 && 
                  <h2 className="subtitle">{blog.summary.substring(0,100)+'...'}</h2>
                }
              </section>
              <section className="article-card_bottom">
                <Link to={`${process.env.PUBLIC_URL}/news/${blog.pathname}`}>
                  <h2>Read More</h2>
                </Link>
                <h2>{new Date(blog.datePublished).toLocaleDateString("en-US")}</h2>
              </section>
            </div>
          </div>
        </motion.div>
        </a>
    );
  }

  return (
    blog && <Link
      to={`${process.env.PUBLIC_URL}/blog/${article}`}
      className="article-card_link"
      key={index}
    >
      <motion.div
        className={`article-card_container ${blog.hidePage === true && "hidden-blog"}`}
        style={{
          backgroundImage: `${blog.thumbnail ? `url(${extractSrcFromHTML(blog.thumbnail)})` : `url(https://www.nisamerica.com/public/blogs/${blog.url}/image.webp) , url(${process.env.PUBLIC_URL}/img/articles/${blog.type}.webp)`}`
        }}
        initial={{ opacity: 0, y: "-20px" }}
        animate={{ opacity: 1, y: "0px" }}
      >
        <div className="article-card_wrapper">
          <div className="article-card_spacing">
            <div className={`article-card_type ${blog.type}`}>
              {blog.type}
            </div>
            <section className="article-card_info">
              <h1>{blog.title}</h1>
              {
                blog.summary && blog.summary.length > 5 && 
                <h2 className="subtitle">{blog.summary.substring(0,100)+'...'}</h2>
              }
            </section>
            <section className="article-card_bottom">
              <Link to={`${process.env.PUBLIC_URL}/news/${blog.pathname}`}>
                <h2>Read More</h2>
              </Link>
              <h2>{new Date(blog.datePublished).toLocaleDateString("en-US")}</h2>
            </section>
          </div>
        </div>
      </motion.div>
    </Link>
  );
};
export default ArticleCard;
