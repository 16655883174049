import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "../styles/pages/home.scss";
import RightArrow from "../components/ui/RightArrow";
import LeftArrow from "../components/ui/LeftArrow";

const TopNews = ({ news }) => {

  const nav = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    draggable: true,
    arrows: true,
    customPaging: function (i) {
      return (
        <a>
          <div className="circle"></div>
        </a>
      );
    },
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
  };

  return (
    <div className="top-news">
      <div className="top-news_header">
        <h1 className="top-news_header-title">LATEST NEWS</h1>
        <Link to="/blog">
          <button className="home-button">MORE NEWS</button>
        </Link>
      </div>
      <section className="top-news_list">
        <Slider {...nav}>
          {news
            .slice(0, 6)
            .map((article, index) => {
              return (
                <TopNewsCard url={article.url} index={index}/>
              )
            })}
        </Slider>
      </section>
    </div>
  );
};

const TopNewsCard = ({url, index}) => {
  const [blog, setBlog] = useState(false);
  const [error, setError] = useState(null);
  const [blogImage, setBlogImage] = useState("")

  const extractSrcFromHTML = (htmlString) => {
    const srcRegex = /<img\s+[^>]*src="([^"]*)"[^>]*>/i;
    const match = htmlString.match(srcRegex);
  
    if (match && match[1]) {
      return match[1];
    }
  
    return null;
  };
  
  const fetchData = async () => {
    try {
      const response = await axios.get(`https://nisamerica.com/api/blog-card/${url}`);

      try {
        const imageCheck = await axios.get(`https://www.nisamerica.com/public/blogs/${url}/background.webp`);
        setBlogImage(`https://www.nisamerica.com/public/blogs/${url}/background.webp`)
      } catch (error) {
        setBlogImage(
          response.data.blog.thumbnail.length > 5 ?
          `${extractSrcFromHTML(response.data.blog.thumbnail)}` :
          `https://www.nisamerica.com/img/articles/${response.data.blog.type}.webp`
        )
      }


      setBlog(response.data.blog);
      console.log(response)
    } catch (error) {
      setError(error.message);
      console.log(error)
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  if(blog && blog.external) {
    return (
        <div>
          <a className="top-news_card-link external" href={blog.external} target="_blank" rel="noopener noreferrer">
          <motion.div
              className="top-news_card"
              key={`article-${index}`}
              style={{
                backgroundImage: `url(${blogImage})`
              }}
              initial={{ opacity: 0, y: "-20px" }}
              animate={{ opacity: 1, y: "0px" }}
            >
              <div className="top-news_wrapper">
                <div className="top-news_info">
                  <h2 className={`top-news_type ${blog.type}`}>
                    {blog.type}
                  </h2>
                  <h1 className="top-news_article-title">
                    {blog.title}
                  </h1>
                  <div className="top-news_button">
                    <button className="top-news_read-more">
                      READ MORE
                      {
                        //arrow svg
                      }
                    </button>
                    <h1 className="top-news_article-date">
                      {new Date(blog.datePublished).toLocaleDateString('en-US')}
                    </h1>
                  </div>
                </div>
              </div>
            </motion.div>
          </a>
        </div>
    )
  }
  
  return (
    <div>
        <Link
          to={`/blog/${url}`}
          className="top-news_card-link"
        >
          <motion.div
            className={`top-news_card ${blog.hidePage && "unpublished"}`}
            key={`article-${index}`}
            style={{
              backgroundImage: `url(${blogImage})`
            }}
            initial={{ opacity: 0, y: "-20px" }}
            animate={{ opacity: 1, y: "0px" }}
          >
            <div className="top-news_wrapper">
              <div className="top-news_info">
                <h2 className={`top-news_type ${blog.type}`}>
                  {blog.type}
                </h2>
                <h1 className="top-news_article-title">
                  {blog.title}
                </h1>
                <div className="top-news_button">
                  <button className="top-news_read-more">
                    READ MORE
                    {
                      //arrow svg
                    }
                  </button>
                  <h1 className="top-news_article-date">
                    {new Date(blog.datePublished).toLocaleDateString('en-US')}
                  </h1>
                </div>
              </div>
            </div>
          </motion.div>
        </Link>
      </div>
  )
  }

export default TopNews;
