import { Helmet } from 'react-helmet';
import { useState } from 'react';
import '../../styles/pages/etc.scss'

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const contentParagraphs = content.split('\n'); // split content into paragraphs

  return (
    <div className="accordion-item">
      <div className="accordion-header" onClick={toggleAccordion}>
        <h1>{title}</h1>
        <div className="accordion-icon">{isOpen ? " -" : " +"}</div>
      </div>
      {isOpen && (
        <div className="accordion-content">
          {contentParagraphs.map((paragraph, index) => (
            <p 
              key={index}
              dangerouslySetInnerHTML={{ __html: paragraph}}
              ></p>
          ))}
        </div>
      )}
    </div>
  );
};

const faqs = [
  {
    title: "Where can I find the release date for a particular title or product? / What platforms will a certain title be available on? / Can you please localize a particular title?",
    content: `We share updates for all of our titles via our social media channels, Discord server, and newsletters. Please make sure you follow us so you can get all the latest news, including announcements, release dates, and more!
    You can find our social media channels at the links below:
    <a href="https://www.facebook.com/NISAmericaInc">Facebook</a>
    <a href="https://twitter.com/nisamerica">Twitter</a>
    <a href="https://www.instagram.com/nisamerica/">Instagram</a>
    <a href="https://discord.com/invite/76DAxQd">Discord</a>
    You can also view more information about each of our titles by visiting the <a href="https://nisamerica.com/games">Games page</a> on our official website.
    Finally, you can also subscribe to our <a href="https://nisamerica.us11.list-manage.com/subscribe/post?u=e8fc423eff95099d1ffcc6009&id=349f7d20f9">newsletters</a> to get the latest information for all of our upcoming games!`,
  },
  {
    title: "What language options are included in a particular game?",
    content: `Language options are listed on the individual game page for each of our titles. You can visit our <a href="https://nisamerica.com/games">Games</a> page to learn more.`,
  },
  {
    title: "Can I be added to your mailing list?",
    content: `If you would like to be added to our mailing list, please feel free to subscribe to our <a href="https://nisamerica.us11.list-manage.com/subscribe/post?u=e8fc423eff95099d1ffcc6009&id=349f7d20f9">newsletters</a> to get the latest information for all of our upcoming games!`,
  },
  {
    title: "Are you going to a certain convention / event?",
    content: `Please keep an eye on our social media channels, Discord server, and newsletters for more information on event and convention attendance.`,
  },
  {
    title: "Where can I preorder / buy your products?",
    content: `You can find Limited Editions and exclusive merchandise items at the <a href="https://store.nisamerica.com/">NISA Online Store</a>. You can also go to our <a href="https://nisamerica.com/games">Games</a> page and visit the Product page for each of our titles to find out where you can pick up the Standard Editions of our titles.`,
  },
  {
    title: "Can I show your anime titles at my event? / I'd like to request copies of your anime titles for my event.",
    content: `If you would like to show our anime titles at your event, please fill out our <a href="https://docs.google.com/forms/d/e/1FAIpQLSf0XiphJJ3WBm49IdGSuxqvDlI2PDMxwZ4FHTeJA11vMP8xCQ/viewform">Anime Screening Consent Form</a>. Once you've filled out the form, no further action is needed and no further confirmation is required from us, you're good to go!
    Please note, this form only grants permission to screen our titles. We do not supply physical discs for use.`,
  },
  {
    title: "I have a question about a product / order / activity on the NISA Online Store, where do I reach out?",
    content: `For questions regarding your order or any of our products, please reach out to our Online Store Support Team at <a href="storesupport@nisamerica.com">storesupport@nisamerica.com</a>, and they will get back to you as soon as possible.
    For questions regarding purchases made via the NIS Europe Online Store, please reach out to their customer support team via their <a href="https://store.nisaeurope.com/pages/contact-us">contact form</a>, as they will be best equipped to assist you with your order.`,
  },
  {
    title: "I found a bug/issue in a game.",
    content: `Please fill out our <a href="https://docs.google.com/forms/d/e/1FAIpQLSczsWbZEvmY6zLKa_LwcBDqhqQBdakJc5G8cKzGirPDc-5QsA/viewform?usp=sharing">Bug/Issue</a> Reporting Form and provide the requested information.`,
  },
  // Add more FAQ items as needed
];

const disgaeaFaqs = [
  {
    title: `I preordered the Disgaea 7: Vows of the Virtueless Limited Edition from the NISA Online Store but I can’t find my code for the Special Weapons Set DLC. Where can I find it?`,
    content: `All customers who pre ordered Disgaea 7: Vows of the Virtueless from the NISA Online Store had their DLC codes sent to the email address on their preorder. If the email cannot be found, we ask for our customers to check their spam folder as well.
    For easier searching, the DLC email has the subject line: "A shipment from order #______ is on the way." Once found, clicking on "View your order" will take you to an order status page, and your code will be on the top right of that status page.
    
    <img src="./img/faq/NIS_OnlineStore_OrderSample.png" alt="Order Sample"></img>
    If you have an online store account, please log in and check your accounts orders. All customers with a preordered unit will have a separate order for $0.00 in their store account. That $0.00 order will have your DLC code(s) inside.
    <img src="./img/faq/NIS_OnlineStore_OrderSample_2.png" alt= "Order Sample 2"></img>
    If you are still needing assistance, please contact <a href="storesupprot@nisamerica.com">storesupport@nisamerica.com</a> with your NISA Online Store order number, and our store support prinnies will be happy to assist you!`,
  },
  {
    title: "I preordered the Disgaea 7: Vows of the Virtueless Deluxe Edition from a physical retailer but did not receive a code for the Special Weapons Set DLC.",
    content: `Please note that the code for this DLC is only provided to customers who preorder the Disgaea 7: Vows of the Virtueless Deluxe Edition at select participating retailers. You can find a list of participating retailers for all regions by visiting the blog post linked below:
    <a href="https://nisamerica.com/blog/disgaea-7-preorder-dlc">https://nisamerica.com/blog/disgaea-7-preorder-dlc</a>
    If you did not preorder your game from one of the listed retailers, then we cannot provide you with a code for the DLC.
    In most cases, the code for the DLC would either be printed on your receipt or listed on your account under your purchases where digital redemptions are normally located. If you are having trouble locating your code, please reach out to the retailer you received your preorder from and they can assist you.
    If you have any questions or require further assistance, please reach out to our Online Store Support Team at <a href="storesupport@nisamerica.com">storesupport@nisamerica.com</a> and they will assist you as soon as possible.`,
  },
  {
    title: "I downloaded the Disgaea 7: Vows of the Virtueless Digital Deluxe Edition/Complete Edition from Nintendo eShop/PlayStation Store but did not receive a code for the Special Weapons Set DLC.",
    content: `For Nintendo eShop users, the Special Weapons Set DLC should automatically be downloaded to your game if you purchase Disgaea 7: Vows of the Virtueless before October 16. You can visit the in-game DLC Shop to access these weapons.
    For PlayStation Store users, if you purchased Disgaea 7: Vows of the Virtueless before October 16, a code for the Special Weapons Set DLC should be included with your purchase. It may appear under your purchases or on any other page where digital redemptions are normally found. Once you locate your code, you should be able to redeem it through PlayStation Store as normal.
    If you are unable to locate your DLC code via this method, please reach out to our Online Store Support Team at <a href="storesupport@nisamerica.com">storesupport@nisamerica.com</a> for further assistance.
    Once the Special Weapon Set DLC has successfully been installed into your game, you can visit the in-game DLC Shop to access it.`,
  },
];

const FAQ = () => (
<div className="etc-container">
        <Helmet>
            <title>Frequently Asked Questions | NIS America, Inc.</title>
        </Helmet>
        <div className="etc-wrapper">
            <h1 className="title">Frequently Asked Questions</h1>
            <h1 className="header">General Questions </h1>
            <div className="accordion-container">
              {faqs.map((faq, index) => (
                <AccordionItem key={index} title={faq.title} content={faq.content} />
              ))}
            </div>
    </div>
        <div className="etc-wrapper">
            <h1 className="header">Disgaea 7: Vows of the Virtueless Questions</h1>
            <div className="accordion-container">
              {disgaeaFaqs.map((disgaeaFaqs, index) => (
                <AccordionItem key={index} title={disgaeaFaqs.title} content={disgaeaFaqs.content} />
              ))}
            </div>
    </div>
</div>
)

export default FAQ;


