import { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import '../styles/components/related.scss'

const RelatedTitles  = ({game, games, article, articles}) => {

    const [gamesList, setGamesList] = useState([])
    const ref = useRef(null);

    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
      };

    const sortNewest = (a,b) => {
        if(a.publishDate && b.publishDate===undefined){
          return -1
        }
        else if(a.publishDate===undefined && b.publishDate){
          return 1
        }
        else if(a.publishDate > b.publishDate){
          return -1
        }
        else if(a.publishDate <  b.publishDate){
          return 1
        }
        return 0
    }
    
    //LISTS OF RELATED TITLES
    useEffect(()=>{
        let developerFiltered = games.filter(item => item.developer === game.developer).sort(sortNewest)
        let genreFiltered = (games.filter(item => item.genre[0]=== game.genre[0])).sort(sortNewest)
        let filtered = developerFiltered.concat(genreFiltered)
        filtered = [...new Set([...developerFiltered,...genreFiltered])]

        if(filtered.length < 5)
        {
            developerFiltered.map(item => {
                if(filtered.indexOf(item) < 0) 
                    filtered.push(item)
            })
            genreFiltered.map(item => {
                if(filtered.indexOf(item) < 0) 
                    filtered.push(item)
            })

            let latest = games.sort(sortNewest).slice(0,5)
            latest.map(item => {
                if(filtered.indexOf(item) === -1) 
                    filtered.push(item)
            })
        }

        
        if(articles.length > 1) {
            let sharedGames = games.filter(item => articles.includes(item.pathname))
            setGamesList(sharedGames.sort((a,b)=>(a.pathname > b.pathname)))
        }
        else {
            setGamesList(filtered.filter(item => item.pathname !== game.pathname).slice(0,5))
        }
    }, [game, games, articles])
  
    return (
        articles.length === 1 ? 
        <div className={`related-titles-container ${article && "related-titles-container_article"}`}>
            <div className={`related-games-wrapper`}>
                <h1 className="related-header">
                    {
                        article ? "Related Titles:" : "You may be also interested in:"
                    }
                </h1>
                <button className="scroll-right" onMouseDown={()=>(scroll(275))}>
                    <img src={`${process.env.PUBLIC_URL}/img/ui/arrow-right.png`} alt="" />
                </button>
                <button className="scroll-left" onClick={()=>(scroll(-275))}>
                <img src={`${process.env.PUBLIC_URL}/img/ui/arrow-left.png`} alt="" />
                </button>
                <div className="related-titles-list" ref={ref}>
                    {
                        gamesList.map(game => (
                            <Link to={`/games/${game.pathname}`}>
                                <img src={`https://www.nisamerica.com/public/assets/${game.pathname}/tile.webp`} alt={game.fullname} className="related-title"/>
                            </Link>
                        ))
                    }
                </div>
                <Link to='/games'>
                    <div className="more-games-wrapper">
                        <button>
                            VIEW ALL GAMES
                        </button>
                    </div>
                </Link>
            </div>
        </div> :
        <div className={`related-titles-container ${article && "related-titles-container_article"}`}>
            <div className={`related-games-wrapper`}>
                <h1 className="related-header">
                    {
                        article ? "Related Titles:" : "You may be also interested in:"
                    }
                </h1>
                <button className="scroll-right" onMouseDown={()=>(scroll(275))}>
                    <img src={`${process.env.PUBLIC_URL}/img/ui/arrow-right.png`} alt="" />
                </button>
                <button className="scroll-left" onClick={()=>(scroll(-275))}>
                <img src={`${process.env.PUBLIC_URL}/img/ui/arrow-left.png`} alt="" />
                </button>
                <div className="related-titles-list" ref={ref}>
                    {
                        gamesList.map(game => (
                            <Link to={`/games/${game.pathname}`}>
                                <img src={`https://www.nisamerica.com/public/assets/${game.pathname}/tile.jpg`} alt={game.fullname} className="related-title"/>
                            </Link>
                        ))
                    }
                </div>
                <Link to='/games'>
                    <div className="more-games-wrapper">
                        <button>
                            VIEW ALL GAMES
                        </button>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default  RelatedTitles;