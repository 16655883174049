import ReactGA from 'react-ga4'
import { useLocation } from "react-router-dom"
import { useEffect } from 'react'

const GA = () => {
    useEffect(()=>{
        ReactGA.initialize("G-E4SC4VFF30");
    })

    const location = useLocation();

    useEffect(()=>{
        ReactGA.send({
            hitType: "pageview", 
            page: location.pathname + location.search
        })
    },[location])

    return null;
}

export default GA;
